import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons/faAngleDoubleDown";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons/faAngleDoubleUp";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faMagic } from "@fortawesome/free-solid-svg-icons/faMagic";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons/faFlagCheckered";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faSquare } from "@fortawesome/free-solid-svg-icons/faSquare";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons/faPoundSign";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons/faLocationArrow";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";

library.add(
    faMap,
    faComments,
    faChartPie,
    faTrashAlt,
    faFileAlt,
    faDownload,
    faUpload,
    faTimes,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faSave,
    faSearch,
    faEdit,
    faPlus,
    faPlusSquare,
    faPlusCircle,
    faLink,
    faBell,
    faCog,
    faCalendarCheck,
    faMagic,
    faUsers,
    faCircleNotch,
    faInfoCircle,
    faTimesCircle,
    faCheckCircle,
    faEnvelope,
    faThumbsUp,
    faEye,
    faArrowRight,
    faInfo,
    faFlagCheckered,
    faPrint,
    faPencilAlt,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faFileExcel,
    faPaperPlane,
    faChartLine,
    faFilter,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faSquare,
    faCheckSquare,
    faPhone,
    faMobileAlt,
    faExclamationCircle,
    faCaretUp,
    faCaretDown,
    faBuilding,
    faPoundSign,
    faLocationArrow,
    faSignOutAlt,
    faBars
);
dom.watch();
