import React from "react";
import styled, { keyframes } from "styled-components";

export const Table = styled.table`
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    border-collapse: collapse;
`;

export const TableRow = styled.tr`
    transition: height 500ms;
    &:hover td {
        background-color: #f2f2f2;
    }
`;

export const TableData = styled.td`
    padding: 12px;
    border-top: 1px solid #f2f2f2;
    margin: 0;

    &.center {
        text-align: center;
    }

    &.heading {
        font-weight: bold;
        width: 35%;
    }
`;

export const TableHeader = styled.th`
    text-align: left;
    padding: 12px;
    font-weight: bold;
    margin: 0;

    &.center {
        text-align: center;
    }
`;
