import React from "react";
import styled from "styled-components";

const StyledLogo = styled.img`
    width: 90%;
    max-width: 100px;
`;

const Logo = ({ ...rest }) => {
    return <StyledLogo alt="Porsche Logo" src="/logo.png" {...rest} />;
};

export default Logo;
