import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Button, Row, Column, Modal } from "@cortexglobal/rla-components";
import { AuthContext } from "@cortexglobal/lens-auth";

import LoadingPage from "../../components/LoadingPage";
import { humanizeSnakeCase } from "../../helpers";

import {
    Table,
    TableHeader,
    TableData,
    TableRow,
} from "../../components/TableComponents";
import { loadContact } from "./Contacts";
import { EditBooking } from "./EditBooking";

const SpacedRow = styled(Row)`
    margin-top: 2.5rem;
`;
const ContactDetails = ({ match }) => {
    const [contactDetails, setContactDetails] = useState({
        loaded: false,
        data: {},
    });
    const [selectedBooking, setSelectedBooking] = useState();

    const { user } = useContext(AuthContext);
    const { centre } = user;
    useEffect(() => {
        loadContact(match.params.uuid).then(({ data }) => {
            setContactDetails({ loaded: true, data: data.data });
        });
    }, []);

    if (!contactDetails.loaded) {
        return <LoadingPage title="Loading contact details" />;
    }
    const contact = contactDetails.data;

    return (
        <>
            <Row>
                <Column
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h2>
                        {contact.first_name} {contact.last_name}{" "}
                        {contact.reference ? `(${contact.reference})` : ""}
                        {!centre && contact.centre && (
                            <small>{contact.centre.name}</small>
                        )}
                    </h2>
                    <Button
                        style={{ lineHeight: "36px" }}
                        as={Link}
                        to={`/centre-area/contacts/${contact.uuid}/edit`}
                    >
                        Edit
                    </Button>
                </Column>
            </Row>
            <Row>
                <Column medium={6}>
                    <p>
                        Email:{" "}
                        <strong>{contact.email ? contact.email : "-"}</strong>
                    </p>
                </Column>

                <Column medium={6}>
                    <p>
                        Phone:{" "}
                        <strong>{contact.phone ? contact.phone : "N/A"}</strong>
                    </p>
                </Column>
                {contact.requirements && contact.requirements.length > 0 && (
                    <Column>
                        <p>
                            Requirements:{" "}
                            <strong>
                                {contact.requirements.map(
                                    (requirement, index) => {
                                        return `${requirement.text}${
                                            index <
                                            contact.requirements.length - 1
                                                ? ", "
                                                : ""
                                        }`;
                                    }
                                )}
                            </strong>
                        </p>
                    </Column>
                )}
            </Row>
            {contact.invites && contact.invites.length > 0 && (
                <SpacedRow>
                    <Column>
                        <h3>Invites</h3>
                    </Column>
                    <Column>
                        <Table>
                            <TableRow>
                                <TableHeader grow={1}>Event</TableHeader>
                                <TableHeader>Code</TableHeader>

                                <TableHeader>Status</TableHeader>
                            </TableRow>
                            <tbody>
                                {contact.invites.map((invite) => {
                                    return (
                                        <TableRow>
                                            <TableData>
                                                {invite.hostedEvent
                                                    ? invite.hostedEvent.title
                                                    : "-"}
                                            </TableData>
                                            <TableData>
                                                {invite.code}{" "}
                                            </TableData>
                                            <TableData>
                                                {humanizeSnakeCase(
                                                    invite.status
                                                )}
                                            </TableData>
                                        </TableRow>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Column>
                </SpacedRow>
            )}

            {contact.bookings && contact.bookings.length > 0 && (
                <SpacedRow>
                    <Column>
                        <h3>Bookings</h3>
                    </Column>
                    <Column>
                        <Table>
                            <TableRow>
                                <TableHeader grow={1}>Event</TableHeader>
                                <TableHeader>Session</TableHeader>

                                <TableHeader>Status</TableHeader>
                                <TableHeader />
                            </TableRow>
                            <tbody>
                                {contact.bookings.map((booking) => {
                                    return (
                                        <TableRow>
                                            <TableData>
                                                {booking.hostedEvent
                                                    ? booking.hostedEvent.title
                                                    : "-"}
                                            </TableData>
                                            <TableData>
                                                {booking.sessionBlock
                                                    ? booking.sessionBlock.name
                                                    : "-"}
                                            </TableData>
                                            <TableData>
                                                {humanizeSnakeCase(
                                                    booking.status
                                                )}
                                            </TableData>

                                            <TableData>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedBooking(
                                                            booking
                                                        );
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                            </TableData>
                                        </TableRow>
                                    );
                                })}

                                {selectedBooking && (
                                    <Modal
                                        visible={!!selectedBooking}
                                        onClose={() => setSelectedBooking()}
                                    >
                                        <div style={{ padding: "3em" }}>
                                            <h3>Event</h3>
                                            <p>
                                                <b>Title: </b>
                                                {
                                                    selectedBooking.hostedEvent
                                                        .title
                                                }
                                            </p>
                                            <p>
                                                <b>Venue: </b>
                                                {
                                                    selectedBooking.hostedEvent
                                                        .venue
                                                }
                                            </p>
                                            <p>
                                                <b>Tagline: </b>
                                                {
                                                    selectedBooking.hostedEvent
                                                        .tagline
                                                }
                                            </p>
                                            <br />

                                            <h3>Session Block</h3>
                                            {selectedBooking.sessionBlock
                                                .uuid ? (
                                                <>
                                                    <p>
                                                        <b>Session: </b>
                                                        {
                                                            selectedBooking
                                                                .sessionBlock
                                                                .name
                                                        }
                                                    </p>
                                                    <p>
                                                        <b>Starting at: </b>
                                                        {new Date(
                                                            selectedBooking.sessionBlock.start_date
                                                        ).toDateString()}
                                                        ,{" "}
                                                        {
                                                            selectedBooking
                                                                .sessionBlock
                                                                .start_time
                                                        }
                                                    </p>
                                                    <p>
                                                        <b>Ending at: </b>
                                                        {new Date(
                                                            selectedBooking.sessionBlock.end_date
                                                        ).toDateString()}
                                                        ,{" "}
                                                        {
                                                            selectedBooking
                                                                .sessionBlock
                                                                .end_time
                                                        }
                                                    </p>
                                                </>
                                            ) : (
                                                <p>
                                                    Session Unavailable - not
                                                    set up or deleted
                                                </p>
                                            )}
                                            <br />

                                            <h3>Details</h3>
                                            <p>
                                                <b>Party size: </b>
                                                {selectedBooking.party_size}
                                            </p>
                                            <p>
                                                <b>Status: </b>
                                                {selectedBooking.status}
                                            </p>
                                        </div>
                                    </Modal>
                                )}
                            </tbody>
                        </Table>
                    </Column>
                </SpacedRow>
            )}
        </>
    );
};

export default ContactDetails;
