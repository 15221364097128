import React, { useState, useEffect } from "react";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    StyledCheckbox,
} from "../../components/FormComponents";

const CustomerDetailsSection = ({
    touched,
    errors,
    values,
    setFieldValue,
    setStage,
    eventDetails,
    submitting,
}) => {
    let isSectionTwoCompleted =
        !!values.title &&
        !!values.first_name &&
        !!values.last_name &&
        !!values.email;

    if (!!eventDetails.terms_and_conditions) {
        isSectionTwoCompleted =
            isSectionTwoCompleted && !!values.agree_to_terms_and_conditions;
    }

    return (
        <>
            <h3>Your details: </h3>
            <StyledLabel htmlFor="title">
                Title <Required />
            </StyledLabel>
            <StyledField
                as="select"
                name="title"
                value={values.title}
                onChange={(value) => setFieldValue("title", value.target.value)}
            >
                <option value="">Select</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
            </StyledField>
            {errors.title && touched.title ? (
                <Error>Title is required</Error>
            ) : null}
            <StyledLabel htmlFor="first_name">
                First name <Required />
            </StyledLabel>
            <StyledField id="first_name" name="first_name" />
            {errors.first_name && touched.first_name ? (
                <Error>First name is required</Error>
            ) : null}
            <StyledLabel htmlFor="last_name">
                Surname <Required />
            </StyledLabel>
            <StyledField id="last_name" name="last_name" />
            {errors.last_name && touched.last_name ? (
                <Error>Surname is required</Error>
            ) : null}
            <StyledLabel htmlFor="phone">Main Telephone Contact</StyledLabel>
            <StyledField id="phone" name="phone" />
            {errors.phone && touched.phone ? (
                <Error>Main telephone contact is required</Error>
            ) : null}
            <StyledLabel htmlFor="email">
                Email <Required />
            </StyledLabel>
            <StyledField id="email" name="email" />
            {eventDetails.max_party_size > 1 && (
                <>
                    <h3 style={{ marginTop: "3rem" }}>Your party: </h3>
                    <StyledLabel htmlFor="party_size">
                        Number in your party ({eventDetails.min_party_size}-
                        {eventDetails.max_party_size})<Required />
                    </StyledLabel>
                    <StyledField
                        id="party_size"
                        name="party_size"
                        type="number"
                        min={eventDetails.min_party_size}
                        max={eventDetails.max_party_size}
                    />
                    {errors.party_size && touched.party_size ? (
                        <Error>The number in your party is required</Error>
                    ) : null}
                </>
            )}
            {!!eventDetails.terms_and_conditions && (
                <>
                    <StyledCheckbox
                        type="checkbox"
                        name="agree_to_terms_and_conditions"
                    />
                    <Required />I have read and agree to the{" "}
                    <a href="#conditions">terms and conditions.</a>
                </>
            )}
            <SubmitSection>
                {eventDetails.timeSlots.length > 1 && (
                    <SubmitButton
                        style={{
                            marginRight: "1rem",
                        }}
                        onClick={() => {
                            setStage(1);
                        }}
                        type="button"
                    >
                        Previous Step
                    </SubmitButton>
                )}

                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    {!!eventDetails.formElements.length ? (
                        <SubmitButton
                            disabled={!isSectionTwoCompleted}
                            hasArrow
                            onClick={() => {
                                setStage(3);
                            }}
                            type="button"
                        >
                            Next Step
                        </SubmitButton>
                    ) : (
                        <>
                            {submitting && <Loader />}
                            <SubmitButton type="submit">Submit</SubmitButton>
                        </>
                    )}
                </div>
            </SubmitSection>
        </>
    );
};

export default CustomerDetailsSection;
