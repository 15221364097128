import React from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import { useHistory } from "react-router-dom";

const PageNotFound = (props) => {
    let history = useHistory();

    return (
        <div style={{ padding: "2em 0 1em" }}>
            <Row style={{ textAlign: "center", padding: "2em 0 1em" }}>
                <Column>
                    <h1>404: Page not found</h1>
                    <p>Oops, the page you were looking for does not exist.</p>
                    <button onClick={() => history.goBack()}>Back</button>
                </Column>
            </Row>
        </div>
    );
};

export default PageNotFound;
