import React, { useState, useEffect } from "react";
import axios from "axios";
import { isEmpty, get } from "lodash";

import {
    SubmitButton,
    SelectField,
    useAddToast,
} from "@cortexglobal/rla-components";

const UploadForm = (props) => {
    const [loadingState, setLoadingState] = useState("loaded");
    const [importOptionsLoadingState, setImportOptionsLoadingState] = useState(
        "loading"
    );

    const [importOptions, setImportOptions] = useState([]);
    const [formParams, setFormParams] = useState({
        import_uuid: "",
    });
    const [uploadFile, setUploadFile] = useState();
    const [errors, setErrors] = useState({});
    const [fileKey, setFileKey] = useState();

    const addToast = useAddToast();

    useEffect(() => {
        getList();
    }, []);

    const getList = (page) => {
        setImportOptionsLoadingState("loading");

        axios
            .get(`/api/v1/imports/options`)
            .then(({ data }) => {
                const options = data.data.map((importOption) => {
                    return {
                        value: importOption.uuid,
                        text: importOption.name,
                    };
                });

                setImportOptions(options);

                setImportOptionsLoadingState("loaded");
            })
            .catch((e) => {
                setImportOptionsLoadingState("error");
            });
    };

    const onChange = (e) => {
        setErrors({});
        setUploadFile(e.target.files[0]);
    };

    const resetFileInput = () => {
        setFileKey(Math.random()); // file input can be reset by randomly changing it's key
    };

    const onSubmit = (event) => {
        event.preventDefault();

        setLoadingState("loading");
        setErrors({});

        const formData = new FormData();

        formData.append("import_uuid", formParams.import_uuid);
        formData.append("file", uploadFile);

        return axios
            .post(`/api/v1/uploads`, formData)
            .then(({ data }) => {
                props.callback(data.data);

                addToast({
                    type: "success",
                    content: "File uploaded",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setErrors(data.errors);
                    }
                }

                addToast({
                    type: "alert",
                    content:
                        e.response && e.response.data
                            ? e.response.data.message
                            : "An error has occurred",
                    showFor: 5000,
                });
            })
            .finally(() => {
                resetFileInput();
                setLoadingState("loaded");
            });
    };

    return (
        <form onSubmit={onSubmit}>
            {!isEmpty(errors.file) && (
                <ul>
                    {errors.file.map((error) => {
                        return <li style={{ color: "red" }}>{error}</li>;
                    })}
                </ul>
            )}

            <input
                type="file"
                name="file"
                onChange={(e) => onChange(e)}
                disabled={loadingState === "loading"}
                key={fileKey}
                style={{
                    width: "100%",
                    margin: "1rem 0",
                    color: !isEmpty(errors.file) ? "red" : "inherit",
                }}
            />

            <SelectField
                name="import_uuid"
                options={importOptions}
                onChange={(e) =>
                    setFormParams({ ...formParams, import_uuid: e.value })
                }
                value={get(formParams, "import_uuid", "")}
                emptyOption={
                    importOptionsLoadingState === "loading"
                        ? " - Loading, please wait - "
                        : " - Optional: Select an import * - "
                }
            />

            <SubmitButton
                label="Upload"
                submitting={loadingState === "loading"}
                disabled={importOptionsLoadingState === "loading"}
            />

            <p style={{ marginTop: "1em", fontSize: "1rem" }}>
                * If no import is chosen an import will be automatically matched
                to an import where possible.
            </p>
        </form>
    );
};

export default UploadForm;
