import React from "react";
import { Loading, Row, Column } from "@cortexglobal/rla-components";

const LoadingPage = (props) => {
    return (
        <div style={{ padding: "2em 0 1em" }}>
            <Row style={{ textAlign: "center", padding: "2em 0 1em" }}>
                <Column>
                    <h1>{props.title || "Loading"}</h1>
                    <p>Please wait...</p>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Loading />
                </Column>
            </Row>
        </div>
    );
};

export default LoadingPage;
