import React from "react";

const AuthContext = React.createContext({
    setLoadingState: () => {},
    loadingState: "loading",
    loggedIn: false,
    user: {},
});

export default AuthContext;
