import React from "react";

import { Row, Column, Panel } from "@cortexglobal/rla-components";

const PageTitle = (props) => {
    const { children, title } = props;

    return (
        <Panel
            {...props}
            style={{
                paddingTop: ".4rem",
                paddingBottom: ".4rem",
            }}
        >
            {children || (
                <Row>
                    <Column>
                        <h1>{title}</h1>
                    </Column>
                </Row>
            )}
        </Panel>
    );
};

export default PageTitle;
