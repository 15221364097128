import React from "react";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TrueFalseIcon = ({ status }) => {
    return status ?
        <FontAwesomeIcon
            style={{ color: "#11bb26" }}
            icon={faCheckCircle}
        />
        :
        <FontAwesomeIcon
            style={{ color: "#d11717" }}
            icon={faTimesCircle}
        />;
};

export default TrueFalseIcon;