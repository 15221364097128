import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Button } from "@cortexglobal/rla-components";

import { TableData, TableRow } from "../../components/TableComponents";
const HostedEventRow = ({ hostedEvent, centre }) => {
    return (
        <>
            <TableRow>
                <TableData>
                    {hostedEvent.series && (
                        <p>
                            <strong>{hostedEvent.series.name}</strong>
                        </p>
                    )}
                    {hostedEvent.name}
                </TableData>
                <TableData>
                    {hostedEvent.from &&
                        moment.utc(hostedEvent.from).format("DD/MM/YYYY")}
                </TableData>
                <TableData>
                    {hostedEvent.to &&
                        moment.utc(hostedEvent.to).format("DD/MM/YYYY")}
                </TableData>
                <TableData>
                    {hostedEvent.venue ? hostedEvent.venue.name : "-"}
                </TableData>
                <TableData style={{ textAlign: "right" }}>
                    {centre && hostedEvent.alias && (
                        <Button
                            style={{ lineHeight: "36px" }}
                            as={Link}
                            to={`/${hostedEvent.alias}?centre_uuid=${centre.uuid}`}
                        >
                            Register Customer
                        </Button>
                    )}
                    <Button
                        style={{ lineHeight: "36px" }}
                        as={Link}
                        to={`/centre-area/event/${hostedEvent.uuid}`}
                    >
                        View
                    </Button>
                </TableData>
            </TableRow>
        </>
    );
};

export default HostedEventRow;
