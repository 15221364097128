export const humanizeSnakeCase = (label, makeTitleCase = true) => {
    let i,
        frags = label.split("_");
    if (makeTitleCase) {
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
    }
    return frags.join(" ");
};
