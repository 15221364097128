import axios from "axios";
import { get } from "lodash";
import {
    getItem,
    setItem,
    clearStorage,
    isJSON,
} from "@cortexglobal/cortex-utilities";

export const setupAuth = (props) => {
    return Promise.all([getItem("access_token"), getItem("user")]).then(
        (values) => {
            const auth = values.reduce((authObj, value) => {
                const key = Object.keys(value)[0];
                return {
                    ...authObj,
                    [key]: isJSON(value[key])
                        ? JSON.parse(value[key])
                        : value[key],
                };
            }, {});

            let { access_token: token, user } = auth;

            axios.defaults.headers.common["Authorization"] = token;

            if (!token) {
                return {
                    loadingState: "loaded",
                    loggedIn: false,
                    token: "",
                    user: {},
                };
            }

            if (!user) {
                return getUser()
                    .then((userData) => {
                        user = userData.user;

                        return {
                            loadingState: "loaded",
                            loggedIn: !!token,
                            token,
                            user,
                        };
                    })
                    .catch((e) => {
                        return {
                            loadingState: "error",
                            loggedIn: !!token,
                            token,
                            user,
                        };
                    });
            }

            return { loadingState: "loaded", loggedIn: !!token, token, user };
        }
    );
};

export const login = (params, redirect = "/") => {
    return axios
        .post(`/oauth/token`, {
            ...params,
            grant_type: "password",
            scope: "*",
        })
        .then(({ data }) => {
            setItem("token", data.access_token);
            setItem(
                "refresh_token",
                `${data.token_type} ${data.refresh_token}`
            );
            setItem("access_token", `${data.token_type} ${data.access_token}`);

            window.location.href = redirect || "/";

            return true; // not that it'll ever get here
        })
        .catch((e) => {
            return false;
        });
};

export const logout = () => {
    return axios
        .post("/api/v1/auth/logout")
        .then(({ data }) => {
            clearStorage();

            window.location.href = "/auth/login";

            return true;
        })
        .catch((e) => {
            return false;
        });
};

export const getUser = () => {
    return axios
        .get(`/api/v1/auth/current-user`)
        .then((response) => {
            let user = get(response, "data.data", {});

            setItem("user", user);

            return {
                user,
            };
        })
        .catch((e) => e);
};

export const forgotten = (params, redirect = "/") => {
    return axios
        .post(`/api/v1/password/forgotten`, params)
        .then(({ data }) => {
            return data.data;
        })
        .catch((e) => {
            if (e.response !== undefined) {
                const { status, data } = e.response;

                if (status === 422 && data.errors !== undefined) {
                    return { success: false, ...data };
                }
            }

            return { success: false };
        });
};

export const resetPassword = (params) => {
    return axios
        .post(`/api/v1/password/reset`, params)
        .then(({ data }) => {
            return data.data;
        })
        .catch((e) => {
            if (e.response !== undefined) {
                const { status, data } = e.response;

                if (status === 422 && data.errors !== undefined) {
                    return { success: false, ...data };
                }
            }

            return { success: false, response_trans: "" };
        });
};
