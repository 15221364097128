import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
    Button,
    Column,
    FormikInput,
    Panel,
    Row, useAddToast,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";


const styledPanel = {
    padding: '2em',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};


const Schema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string().required("Please confirm your password"),
});

const ResetPassword = ({ props }) => {

    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    const queryString = new URLSearchParams(useLocation().search);
    const token = queryString.get("token")?.toLowerCase() || "";
    const addToast = useAddToast();

    const handleSubmit = value => {
        setLoading(true);

        axios
            .post(`/api/v1/auth/password/reset`, {
                token: token,
                email: value.email,
                password: value.password,
                password_confirmation: value.password_confirmation,
            })
            .then(({ data }) => {
                setLoading(false);
                window.location = "/auth/login"
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error changing your password.",
                    showFor: 5000
                });
            });
    };



    return (
        <>
            <Panel
                style={styledPanel}
            >
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        password_confirmation: '',
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                >
                    {({
                          isSubmitting,
                          values,
                          setFieldValue,
                          errors
                      }) => {
                        return (
                            <Form>
                                <>
                                    <Row>
                                        <Column>
                                            <FormikInput
                                                value={values.email}
                                                width="100%"
                                                labelWidth={100}
                                                name="email"
                                                label="Email Address"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column>
                                            <FormikInput
                                                value={values.password}
                                                type="password"
                                                width="100%"
                                                labelWidth={100}
                                                name="password"
                                                label="New Password"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column>
                                            <FormikInput
                                                value={values.password_confirmation}
                                                type="password"
                                                width="100%"
                                                labelWidth={100}
                                                name="password_confirmation"
                                                label="Confirm Password"
                                            />
                                        </Column>
                                    </Row>
                                    {/*<span className="error" style={{color: "red"}}>*/}
                                    {/*  {errors.password}*/}
                                    {/*</span>*/}
                                    <Row>
                                        <Column style={{
                                            textAlign: 'right'
                                        }}>
                                            <Button
                                                type='submit'
                                            >
                                                Change Password
                                            </Button>
                                        </Column>
                                    </Row>
                                </>
                            </Form>
                        )
                    }}
                </Formik>
            </Panel>
        </>
    );
};

export default ResetPassword;