import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import ReportListing from "./ReportListing";

const UserReports = (props) => {
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loadingState, setLoadingState] = useState("loaded");

    const refreshList = () => {
        getList(1);
    };

    const getList = (page) => {
        setLoadingState("loading");

        const params = {
            page: page === undefined ? 1 : page,
        };

        axios
            .get(`/api/v1/reporting`, { params: params })
            .then(({ data }) => {
                setReports(data.data);
                setPagination(data.meta);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => {
        getList(1);
        // setInterval(() => {
        //     refreshList()
        // }, 20000);
    }, []);

    return (
        <>
            <PageTitle title="My Reports" expanded />
            <Row collapse expanded>
                <Column collapse>
                    <Panel style={{ paddingTop: "0" }}>
                                <ReportListing
                                    reports={reports}
                                    pagination={pagination}
                                    reload={getList}
                                    loadingState={loadingState}
                                />
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default UserReports;
