import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Field } from "formik";
import axios from "axios";

import { Modal, useAddToast } from "@cortexglobal/rla-components";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    StyledInput,
} from "../../components/FormComponents";

const TimeSlotContainer = styled.div`
    margin-bottom: 1em;
    display: flex;
    align-items: center;
`;

const TimeSlot = styled.label`
    display: block;
    width: 100%;
    padding: 2em;
    cursor: pointer;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 0 0 1px black;
    }

    ${({ selected }) =>
        selected &&
        `
        background: #f1eeee;
    `}

    ${({ disabled }) =>
        disabled &&
        `
        opacity: 0.5;
        cursor: unset;
        
        &:hover {
            box-shadow: unset;
        }
    `}
    
    ${({ availability }) => {
        if (availability === "low") {
            return `border-left: 10px solid orange;`;
        }

        if (availability === "critical") {
            return `border-left: 10px solid #d81515;`;
        }
    }}
`;

const TimeSlotField = styled(Field)`
    position: absolute;
    top: -100px;
`;

const TimeSlotSelection = ({
    setFieldValue,
    values,
    handleTimeSlotSubmit,
    eventDetails,
    timeSlotError,
    timeSlotSubmitting,
    invitationCode = "",
    getEventDetails,
    error,
    centre_uuid,
}) => {
    const [waitingListModalShowing, setWaitingListModalShowing] =
        useState(false);

    const [code, setCode] = useState(invitationCode);
    const [waitingListId, setWaitingListId] = useState(null);
    const [waitingListLoading, setWaitingListLoading] = useState(false);
    const [waitingListEmail, setWaitingListEmail] = useState();
    const [waitingListPhone, setWaitingListPhone] = useState();
    const [waitingListErrors, setWaitingListErrors] = useState({});
    const addToast = useAddToast();

    const handleAddToWaitingList = (id) => {
        setWaitingListId(id);
        setWaitingListModalShowing(true);
    };

    const resetForm = () => {
        setWaitingListId(null);
        setWaitingListEmail();
        setWaitingListPhone();
        setWaitingListErrors({});
    };

    const addToWaitingList = () => {
        setWaitingListLoading(true);

        axios
            .post(`/api/v1/hosted-event/waiting-list`, {
                session_id: waitingListId,
                email: waitingListEmail,
                phone: waitingListPhone,
                centre_uuid,
                invite_code: code,
            })
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: "Successfully added to the waiting list",
                    showFor: 5000,
                });
                setWaitingListModalShowing(false);
                resetForm();
            })
            .catch((e) => {
                setWaitingListModalShowing(true);

                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setWaitingListErrors(data.errors);
                    }
                }
            })
            .finally(() => {
                setWaitingListLoading(false);
            });
    };

    const readableTimeSlot = (from, to) => {
        const d1 = new Date(from);
        const d2 = new Date(to);
        if (
            d1.getFullYear() === d1.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDay() === d2.getDay()
        ) {
            return `${d1.toLocaleDateString("en-gb", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
            })}, ${d1.toLocaleTimeString()} - ${d2.toLocaleTimeString()}`;
        }
        return `${d1.toLocaleDateString("en-gb", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
        })}, ${d1.toLocaleTimeString()} - ${d2.toLocaleDateString("en-gb", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
        })}, ${d2.toLocaleTimeString()}`;
    };

    if (
        eventDetails.invite_only &&
        (!code || eventDetails.invite_status === "unchecked")
    ) {
        return (
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    getEventDetails(code);
                }}
            >
                <div>
                    <StyledLabel htmlFor="code">
                        Please enter your invitation code <Required />
                    </StyledLabel>
                    <StyledField
                        as="input"
                        name="code"
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                        }}
                    />
                </div>
                {eventDetails.invite_status === "invalid" && (
                    <Error>Please check your invitation code</Error>
                )}
                <SubmitSection>
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        <SubmitButton
                            style={{
                                marginLeft: "1rem",
                                minWidth: "220px",
                            }}
                            hasArrow
                        >
                            Submit invitation code
                        </SubmitButton>
                    </div>
                </SubmitSection>
            </form>
        );
    }
    return (
        <>
            <h3>Select a time slot: </h3>
            {eventDetails.timeSlots.map((timeSlot) => {
                return (
                    <TimeSlotContainer key={timeSlot.uuid}>
                        <TimeSlot
                            disabled={timeSlot.availability === "none"}
                            availability={timeSlot.availability}
                            selected={values.time_slot === timeSlot.id}
                        >
                            <TimeSlotField
                                disabled={timeSlot.availability === "none"}
                                onChange={() => {
                                    setFieldValue("time_slot", timeSlot.id);
                                }}
                                type="radio"
                                name="time_slot"
                                value={timeSlot.id}
                            />
                            <span>
                                {readableTimeSlot(timeSlot.from, timeSlot.to)}
                            </span>
                            {timeSlot.availability === "low" &&
                                timeSlot.availabilityLabel}
                            {timeSlot.availability === "critical" &&
                                timeSlot.availabilityLabel}
                        </TimeSlot>

                        {timeSlot.availability === "none" && (
                            <SubmitButton
                                type="button"
                                onClick={() =>
                                    handleAddToWaitingList(timeSlot.id)
                                }
                                style={{
                                    marginLeft: "1rem",
                                    minWidth: "220px",
                                }}
                                color="red"
                                hasArrow
                            >
                                Add to waiting list
                            </SubmitButton>
                        )}
                    </TimeSlotContainer>
                );
            })}
            <SubmitSection>
                {timeSlotError && (
                    <Error
                        style={{
                            maxWidth: "70%",
                        }}
                    >
                        {timeSlotError}
                    </Error>
                )}
                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    {timeSlotSubmitting && <Loader />}
                    <SubmitButton
                        hasArrow
                        disabled={!values.time_slot}
                        onClick={() => {
                            handleTimeSlotSubmit(values.time_slot);
                        }}
                        type="button"
                    >
                        Next Step
                    </SubmitButton>
                </div>
            </SubmitSection>
            {waitingListModalShowing && (
                <Modal
                    visible={waitingListModalShowing}
                    closeOnWrapperClick={false}
                    onClose={() => {
                        setWaitingListModalShowing(false);
                        resetForm();
                    }}
                >
                    <div style={{ padding: "2em" }}>
                        <h3>Waiting list</h3>
                        <StyledLabel>Email</StyledLabel>
                        <StyledInput
                            name="waiting_list_email"
                            value={waitingListEmail}
                            onChange={(value) =>
                                setWaitingListEmail(value.target.value)
                            }
                            error={waitingListErrors.email}
                            disabled={waitingListLoading}
                        />
                        <Error>
                            {!!waitingListErrors.email &&
                                waitingListErrors.email}
                        </Error>
                        <StyledLabel>Phone</StyledLabel>
                        <StyledInput
                            name="phone"
                            value={waitingListPhone}
                            onChange={(value) =>
                                setWaitingListPhone(value.target.value)
                            }
                            error={waitingListErrors.phone}
                            disabled={waitingListLoading}
                        />
                        <Error>
                            {!!waitingListErrors.phone &&
                                waitingListErrors.phone}
                        </Error>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <SubmitButton
                                type="submit"
                                color="red"
                                hasArrow
                                onClick={addToWaitingList}
                                disabled={waitingListLoading}
                            >
                                Add to waiting list
                            </SubmitButton>
                            {waitingListLoading && <Loader />}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TimeSlotSelection;
