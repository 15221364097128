import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { useQueryString } from "@cortexglobal/lens-components";
import {
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
} from "../../components/FormComponents";

import TimeSlotSelection from "./TimeSlotSelection";
import CustomerDetailsSection from "./CustomerDetailsSection";
import HeroSection from "./HeroSection";
import FormFooter from "./FormFooter";
import LoadingPage from "../../components/LoadingPage";
import LeadQuestion from "./LeadQuestion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const LogoContainer = styled.div`
    text-align: center;
    padding: 1rem;
`;

const Logo = styled.img`
    height: 80px;
`;

const FormContainer = styled.div`
    background: #f9f9f9;
`;

export const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 3rem 15px;
`;

const ErrorIcon = () => {
    return (
        <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{
                color: "red",
                fontSize: "4em",
                margin: "10px",
            }}
        />
    );
};

const getScheme = (termsRequired) => {
    const shape = {
        title: Yup.string().required(),
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        phone: Yup.string(),
        email: Yup.string(),
    };

    if (termsRequired) {
        shape["agree_to_terms_and_conditions"] = Yup.boolean().oneOf(
            [true],
            "Please confirm that you've read and agree to the terms and conditions."
        );
    }

    return Yup.object().shape(shape);
};

const Landing = ({ match }) => {
    const [stage, setStage] = useState(1);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({ code: null, message: "" });

    const [message, setMessage] = useState("");

    const [successful, setSuccessful] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [timeSlotSubmitting, setTimeSlotSubmitting] = useState(false);
    const [timeSlotError, setTimeSlotError] = useState("");
    const [timeSlotData, setTimeSlotData] = useState();

    const [countdown, setCountdown] = useState();
    const [countdownStarted, setCountdownStarted] = useState(false);

    const [eventDetails, setEventDetails] = useState();
    const [invitationCode, setInvitationCode] = useState(match.params.code);
    const centre_uuid = useQueryString().get("centre_uuid");

    const getEventDetails = (submittedCode = null) => {
        setLoading(true);

        const code = submittedCode ? submittedCode : invitationCode;
        setInvitationCode(code);

        const eventAlias = match.params.event;

        let url = "api/v1";

        if (code) {
            url += "/invite/" + code;
        } else if (eventAlias) {
            url += "/hosted-event/" + eventAlias;
        }

        if (url === "api/v1") {
            return;
        }

        axios
            .get(url)
            .then(({ data }) => {
                setError({
                    code: null,
                    message: "",
                });

                setEventDetails(data.data);

                if (data.data.timeSlots.length === 1) {
                    setStage(2);
                    handleTimeSlotSubmit(data.data.timeSlots[0].id);
                }

                setLoading(false);
            })
            .catch((e) => {
                setError({
                    code: e.response.status,
                    message: e.response.data.message,
                });

                setLoading(false);
            });
    };

    useEffect(() => {
        getEventDetails();
    }, []);

    const startTimer = (reference) => {
        let counter = 900;
        // let counter = 5; // drop to 5 secs for testing
        setCountdownStarted(true);

        const intervalId = setInterval(() => {
            counter = --counter;
            setCountdown(counter);

            if (counter === 0) {
                clearInterval(intervalId);
                checkTimeSlotStillAvailable(reference);
                setStage(1);
            }
        }, 1000);
    };

    const checkTimeSlotStillAvailable = (reference) => {
        setCountdownStarted(false);
        setCountdown(0);
        setMessage(
            "Sorry, your reservation has timed-out, please choose again."
        );
        setStage(1);
    };

    const handleOnSubmit = (values) => {
        setSubmitting(true);
        axios
            .post(`/api/v1/hosted-event/book`, {
                ...values,
                reference: timeSlotData.reference,
                centre_uuid,
            })
            .then(({ data }) => {
                setSuccessful(true);
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    setError({
                        code: status,
                        message: data.message,
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleTimeSlotSubmit = (id) => {
        setTimeSlotSubmitting(true);

        axios
            .post(`/api/v1/hosted-event/time-slot`, {
                time_slot: id,
                code: invitationCode,
                centre_uuid,
            })
            .then(({ data }) => {
                const bookingData = data.data;
                setTimeSlotData(bookingData);
                startTimer(bookingData.reference);
                setStage(2);
            })
            .catch((e) => {
                if (error.code < 500) {
                    setTimeSlotError(e.response.data.message);
                }
            })
            .finally(() => {
                setTimeSlotSubmitting(false);
            });
    };

    const formValues = eventDetails
        ? eventDetails.formValues.reduce((accumulator, currentValue) => {
              return {
                  ...accumulator,
                  [currentValue.name]: currentValue.value,
              };
          }, {})
        : {};

    const render = () => {
        if (error.code >= 400) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "500px",
                    }}
                >
                    <ErrorIcon />
                    <p style={{ fontSize: "1.5em" }}>{error.message}</p>
                </div>
            );
        }

        if (successful) {
            return (
                <>
                    <HeroSection eventDetails={eventDetails} />

                    <Container>
                        <h2>Thank you!</h2>
                        <p style={{ fontSize: "1.5em" }}>
                            Your details were successfully submitted.
                        </p>
                    </Container>
                </>
            );
        }
        if (loading) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "500px",
                    }}
                >
                    <LoadingPage
                        title={"Loading the details of your event..."}
                    />
                </div>
            );
        }

        if (timeSlotError) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "500px",
                    }}
                >
                    <ErrorIcon />
                    <p style={{ fontSize: "1.5em" }}>
                        {timeSlotError || "Please try again later"}
                    </p>
                </div>
            );
        }

        const getInitialValues = () => {
            let values = {
                uuid: formValues.uuid ? formValues.uuid : "",
                title: formValues.title ? formValues.title : "",
                first_name: formValues.first_name ? formValues.first_name : "",
                last_name: formValues.last_name ? formValues.last_name : "",
                phone: formValues.phone ? formValues.phone : "",
                email: formValues.email ? formValues.email : "",
                time_slot:
                    eventDetails.timeSlots.length === 1
                        ? eventDetails.timeSlots[0].id
                        : "",
                party_size: formValues.party_size ? formValues.party_size : 1,
            };

            eventDetails.formElements.map((element) => {
                values[element.name] = "";
            });

            return values;
        };

        return (
            <>
                <HeroSection eventDetails={eventDetails} />

                {!!message && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "150px",
                        }}
                    >
                        <ErrorIcon />
                        <p style={{ fontSize: "1.5em" }}>{message}</p>
                    </div>
                )}

                <Container>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: eventDetails.description,
                        }}
                    />
                </Container>

                <FormContainer>
                    <Container>
                        <Formik
                            initialValues={getInitialValues()}
                            validationSchema={getScheme(
                                !!eventDetails.terms_and_conditions
                            )}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        {stage === 1 && (
                                            <TimeSlotSelection
                                                eventDetails={eventDetails}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                handleTimeSlotSubmit={
                                                    handleTimeSlotSubmit
                                                }
                                                timeSlotError={timeSlotError}
                                                timeSlotSubmitting={
                                                    timeSlotSubmitting
                                                }
                                                invitationCode={invitationCode}
                                                getEventDetails={
                                                    getEventDetails
                                                }
                                                error={error}
                                                centre_uuid={centre_uuid}
                                            />
                                        )}

                                        {stage === 2 && (
                                            <CustomerDetailsSection
                                                submitting={submitting}
                                                touched={touched}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                setStage={setStage}
                                                eventDetails={eventDetails}
                                            />
                                        )}

                                        {stage === 3 && (
                                            <>
                                                <h3>Other details: </h3>
                                                {eventDetails.formElements.map(
                                                    (formElement) => (
                                                        <LeadQuestion
                                                            key={
                                                                formElement.name
                                                            }
                                                            {...formElement}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            errors={errors}
                                                            values={values}
                                                        />
                                                    )
                                                )}

                                                {submitError && (
                                                    <Error>{submitError}</Error>
                                                )}

                                                <SubmitSection>
                                                    <SubmitButton
                                                        style={{
                                                            marginRight: "1rem",
                                                        }}
                                                        onClick={() => {
                                                            setStage(2);
                                                        }}
                                                        type="button"
                                                    >
                                                        Previous Step
                                                    </SubmitButton>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexGrow: 1,
                                                            justifyContent:
                                                                "flex-end",
                                                        }}
                                                    >
                                                        {submitting && (
                                                            <Loader />
                                                        )}
                                                        <SubmitButton type="submit">
                                                            Submit
                                                        </SubmitButton>
                                                    </div>
                                                </SubmitSection>
                                            </>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Container>
                </FormContainer>
                <FormFooter
                    eventDetails={eventDetails}
                    countdownStarted={countdownStarted}
                    countdown={countdown}
                    timeSlotSubmitting={timeSlotSubmitting}
                />
            </>
        );
    };

    return (
        <>
            <LogoContainer>
                <Logo src="/logo.png" />
            </LogoContainer>

            {render()}
        </>
    );
};

export default Landing;
