import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Row,
    Column,
    Panel,
    Button,
    useAddToast,
} from "@cortexglobal/rla-components";
import LoadingPage from "../../components/LoadingPage";
import {
    Table,
    TableHeader,
    TableRow,
    TableData,
} from "../../components/TableComponents";
import { Sessions } from "./Sessions";
import { humanizeSnakeCase } from "../../helpers";
const Hero = styled.div`
    height: 30vh;
    background: url(${({ background }) => background}) no-repeat center;
    background-color: rgba(0, 0, 0, 0.9);
    background-size: cover;
    color: white;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
`;

const Title = styled.h1`
    margin-top: 0;
    padding-top: 1rem;
`;
const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;
const Navigation = styled.nav`
    ul {
        display: flex;
        margin: 2rem auto;
        li {
            list-style: none;
        }
    }
`;

const NavLink = styled.a`
    text-decoration: none;
    padding: 1rem 1rem 0.5rem;
    color: ${({ active }) => (active ? "#d5001c" : "inherit")};
    &:hover {
        color: #d5001c;
        border-bottom: 2px solid #d5001c;
    }
`;
const SpacedRow = styled(Row)`
    margin-top: 1.5rem;
`;
export const EventDetails = ({ match }) => {
    const addToast = useAddToast();

    const [page, setPage] = useState("details");
    const [hostedEvent, setHostedEvent] = useState({
        loaded: false,
        error: false,
        data: {},
    });
    let { uuid } = useParams();

    const loadEvent = (uuid) => {
        return axios
            .get(`/api/v1/centre/events/${uuid}`)
            .then(({ data }) => {
                setHostedEvent({ loaded: true, error: false, data: data.data });
            })
            .catch((e) => {
                setHostedEvent({ loaded: true, error: true, data: {} });
            });
    };
    useEffect(() => {
        loadEvent(uuid);
    }, [uuid]);

    const downloadAttendees = () => {
        axios
            .get(`/api/v1/centre/events/${uuid}/download-attendees`)
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${event.name} attendees.csv`);
                document.body.appendChild(link);
                link.click();
            });
    };

    if (!hostedEvent.loaded) {
        return <LoadingPage title="Loading event details" />;
    }

    const event = hostedEvent.data;
    const resendInvite = (invite) => {
        axios
            .post(`/api/v1/invite/${invite.uuid}/emails/resend-invite`)
            .then(() => {
                addToast({
                    type: "success",
                    content: "Successfully resent the invite",
                    showFor: 5000,
                });
            })
            .catch(() => {
                addToast({
                    type: "alert",
                    content: "Error resending the invite",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <Row>
                <Column>
                    <Hero background={event.hero_url}>
                        <Container>
                            <Title>{event.name}</Title>
                            <h2>{event.tag_line}</h2>
                            <p>
                                {event.from &&
                                    format(
                                        new Date(event.from),
                                        "dd/MM/yyyy"
                                    )}{" "}
                                {event.to && (
                                    <>
                                        -{" "}
                                        {format(
                                            new Date(event.to),
                                            "dd/MM/yyyy"
                                        )}
                                    </>
                                )}
                            </p>
                        </Container>
                    </Hero>
                </Column>
            </Row>
            <Row collapse>
                <Navigation>
                    <ul>
                        <li>
                            <NavLink
                                active={page === "details"}
                                href="#details"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setPage("details");
                                }}
                            >
                                Details
                            </NavLink>{" "}
                        </li>
                        <li>
                            <NavLink
                                active={page === "sessions"}
                                href="#sessions"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setPage("sessions");
                                }}
                            >
                                Sessions
                            </NavLink>{" "}
                        </li>
                        {event.invites && event.invites.length > 0 && (
                            <li>
                                <NavLink
                                    active={page === "invites"}
                                    href="#invites"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setPage("invites");
                                    }}
                                >
                                    Invites
                                </NavLink>{" "}
                            </li>
                        )}
                    </ul>
                </Navigation>
            </Row>
            {page === "details" && (
                <SpacedRow equaliseChildHeight={true} id="details">
                    <Column medium={6}>
                        <Panel style={{ height: "100%" }}>
                            <Row>
                                <Column>
                                    <h3>Details</h3>
                                </Column>
                            </Row>

                            <Row>
                                <Column small={8}>
                                    <p>Current Availability</p>
                                </Column>
                                <Column small={4}>
                                    <p>
                                        {event.sessions &&
                                            event.sessions.reduce(
                                                (total, session) => {
                                                    if (session.availability) {
                                                        total +=
                                                            session.availability
                                                                .number;
                                                    }
                                                    return total;
                                                },
                                                0
                                            )}
                                    </p>
                                </Column>
                            </Row>
                            <Row>
                                <Column small={8}>
                                    <p>Your bookings</p>
                                </Column>
                                <Column style={{ display: "flex" }} small={2}>
                                    <p style={{ flexGrow: 1 }}>
                                        {event.bookings &&
                                        event.bookings.length > 0
                                            ? event.bookings.length
                                            : 0}
                                    </p>
                                </Column>
                                {event.bookings && event.bookings.length > 0 && (
                                    <Column
                                        style={{ display: "flex" }}
                                        small={2}
                                    >
                                        <Button onClick={downloadAttendees}>
                                            <FontAwesomeIcon icon="download" />
                                        </Button>
                                    </Column>
                                )}
                            </Row>

                            {event.max_capacity > 0 && (
                                <Row>
                                    <Column small={8}>
                                        <p>Max Capacity</p>
                                    </Column>
                                    <Column small={4}>
                                        <p>{event.max_capacity}</p>
                                    </Column>
                                </Row>
                            )}
                            {event.min_party_size > 0 && (
                                <Row>
                                    <Column small={8}>
                                        <p>Min Party Capacity</p>
                                    </Column>
                                    <Column small={4}>
                                        <p>{event.min_party_size}</p>
                                    </Column>
                                </Row>
                            )}
                            {event.max_party_size > 0 && (
                                <Row>
                                    <Column small={8}>
                                        <p>Max Party Size</p>
                                    </Column>
                                    <Column small={4}>
                                        <p>{event.max_party_size}</p>
                                    </Column>
                                </Row>
                            )}
                            {Array.isArray(event.waiting_list) && (
                                <Row>
                                    <Column small={8}>
                                        <p>Waiting List</p>
                                    </Column>
                                    <Column small={4}>
                                        <p>{event.waiting_list.length || 0}</p>
                                    </Column>
                                </Row>
                            )}
                        </Panel>
                    </Column>
                    {event.venue && (
                        <Column medium={6}>
                            <Panel style={{ height: "100%" }}>
                                <Row>
                                    <Column>
                                        <h3>{event.venue.name}</h3>
                                        {event.venue.address && (
                                            <>
                                                {event.venue.address
                                                    .address_1 && (
                                                    <p>
                                                        {
                                                            event.venue.address
                                                                .address_1
                                                        }
                                                    </p>
                                                )}
                                                {event.venue.address
                                                    .address_2 && (
                                                    <p>
                                                        {
                                                            event.venue.address
                                                                .address_2
                                                        }
                                                    </p>
                                                )}
                                                {event.venue.address
                                                    .address_3 && (
                                                    <p>
                                                        {
                                                            event.venue.address
                                                                .address_3
                                                        }
                                                    </p>
                                                )}
                                                {event.venue.address
                                                    .address_4 && (
                                                    <p>
                                                        {
                                                            event.venue.address
                                                                .address_4
                                                        }
                                                    </p>
                                                )}
                                                {event.venue.address
                                                    .postcode && (
                                                    <p>
                                                        {
                                                            event.venue.address
                                                                .postcode
                                                        }
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </Column>
                                </Row>
                            </Panel>
                        </Column>
                    )}
                </SpacedRow>
            )}
            {page === "sessions" && (
                <SpacedRow id="sessions">
                    <Column>
                        <Sessions event={event} loadEvent={loadEvent} />{" "}
                    </Column>
                </SpacedRow>
            )}
            {page === "invites" && event.invites && event.invites.length > 0 && (
                <SpacedRow id="invites">
                    <Column>
                        <Table>
                            <thead>
                                <tr>
                                    <TableHeader>Name</TableHeader>
                                    <TableHeader>Email</TableHeader>
                                    <TableHeader>Status</TableHeader>
                                    <TableHeader></TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {event.invites.map((invite) => {
                                    return (
                                        <TableRow key={invite.uuid}>
                                            <TableData>
                                                {invite.contact ? (
                                                    <>
                                                        {
                                                            invite.contact
                                                                .first_name
                                                        }{" "}
                                                        {
                                                            invite.contact
                                                                .last_name
                                                        }
                                                    </>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </TableData>
                                            <TableData>
                                                {invite.contact ? (
                                                    <>{invite.contact.email}</>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </TableData>
                                            <TableData>
                                                {humanizeSnakeCase(
                                                    invite.status
                                                )}
                                            </TableData>
                                            <TableData
                                                style={{ textAlign: "right" }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        resendInvite(invite);
                                                    }}
                                                >
                                                    Resend Invite
                                                </Button>
                                            </TableData>
                                        </TableRow>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Column>
                </SpacedRow>
            )}
        </>
    );
};

export default EventDetails;
