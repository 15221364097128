import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Loading } from "@cortexglobal/rla-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import Logo from "../../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
} from "../../components/FormComponents";

import { ThankyouBanner } from "../../components/registration/registrationComponents";
import LoadingPage from "../../components/LoadingPage";

const Schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    reference: Yup.string().required("Required"),
    address_1: Yup.string().required("Required"),
    address_2: Yup.string().nullable(true),
    address_3: Yup.string().nullable(true),
    address_4: Yup.string().nullable(true),
    address_5: Yup.string().nullable(true),
    postcode: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    website: Yup.string().required("Sometimes"),
    facebook_url: Yup.string().nullable(true),
    twitter_url: Yup.string().nullable(true),
    youtube_url: Yup.string().nullable(true),
    instagram_url: Yup.string().nullable(true),
    facebook_tag: Yup.string().nullable(true),
    twitter_tag: Yup.string().nullable(true),
    youtube_tag: Yup.string().nullable(true),
    instagram_tag: Yup.string().nullable(true),
});

const CentreForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [centre, setCentre] = useState({
        loaded: false,
        error: false,
        data: {},
    });
    useEffect(() => {
        axios
            .get(`/api/v1/centre`)
            .then(({ data }) => {
                setCentre({ loaded: true, error: false, data: data.data });
            })
            .catch((e) => {
                setCentre({ loaded: true, error: true, data: {} });
            });
    }, []);

    const handleOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .put(`/api/v1/centre/${centre.data.uuid}`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    if (!centre.loaded) {
        return <LoadingPage title="Loading your centre details" />;
    }

    return (
        <>
            <Container>
                <>
                    <h1 className="title" style={{ marginBottom: "3rem" }}>
                        My Centre
                    </h1>
                    <Formik
                        initialValues={{
                            name: centre.data.name,
                            reference: centre.data.reference,
                            address_1: centre.data.address_1,
                            address_2: centre.data.address_2,
                            address_3: centre.data.address_3,
                            address_4: centre.data.address_4,
                            address_5: centre.data.address_5,
                            postcode: centre.data.postcode,
                            email: centre.data.email,
                            website: centre.data.website,
                            facebook_url: centre.data.facebook_url,
                            twitter_url: centre.data.twitter_url,
                            youtube_url: centre.data.youtube_url,
                            instagram_url: centre.data.instagram_url,
                            facebook_tag: centre.data.facebook_tag,
                            twitter_tag: centre.data.twitter_tag,
                            youtube_tag: centre.data.youtube_tag,
                            instagram_tag: centre.data.instagram_tag,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ errors, touched }) => {
                            return (
                                <Form>
                                    <h3>Centre Details</h3>

                                    <StyledLabel htmlFor="name">
                                        Name <Required />
                                    </StyledLabel>
                                    <StyledField id="name" name="name" />
                                    {errors.name && touched.name ? (
                                        <Error>Name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="reference">
                                        Centre Reference <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="reference"
                                        name="reference"
                                    />
                                    {errors.reference && touched.reference ? (
                                        <Error>
                                            Centre Identifier is required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="address_1">
                                        Address 1 <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="address_1"
                                        name="address_1"
                                    />
                                    {errors.address_1 && touched.address_1 ? (
                                        <Error>Address 1 is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="address_2">
                                        Address 2
                                    </StyledLabel>
                                    <StyledField
                                        id="address_2"
                                        name="address_2"
                                    />

                                    <StyledLabel htmlFor="address_3">
                                        Address 3
                                    </StyledLabel>
                                    <StyledField
                                        id="address_3"
                                        name="address_3"
                                    />

                                    <StyledLabel htmlFor="address_4">
                                        Address 4
                                    </StyledLabel>
                                    <StyledField
                                        id="address_4"
                                        name="address_4"
                                    />

                                    <StyledLabel htmlFor="address_5">
                                        Address 5
                                    </StyledLabel>
                                    <StyledField
                                        id="address_5"
                                        name="address_5"
                                    />

                                    <StyledLabel htmlFor="postcode">
                                        Postcode
                                    </StyledLabel>
                                    <StyledField
                                        id="postcode"
                                        name="postcode"
                                    />
                                    {errors.postcode && touched.postcode ? (
                                        <Error>Postcode is required</Error>
                                    ) : null}

                                    <h3 style={{ marginTop: "2rem" }}>
                                        Contact Details
                                    </h3>

                                    <StyledLabel htmlFor="email">
                                        Email
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>Email is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="website">
                                        Website
                                    </StyledLabel>
                                    <StyledField id="website" name="website" />
                                    {errors.website && touched.website ? (
                                        <Error>Website is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="facebook_url">
                                        Facebook Link
                                    </StyledLabel>
                                    <StyledField
                                        id="facebook_url"
                                        name="facebook_url"
                                    />

                                    <StyledLabel htmlFor="facebook_tag">
                                        Facebook Handle
                                    </StyledLabel>
                                    <StyledField
                                        id="facebook_tag"
                                        name="facebook_tag"
                                    />

                                    <StyledLabel htmlFor="twitter_url">
                                        Twitter_url Link
                                    </StyledLabel>
                                    <StyledField
                                        id="twitter_url"
                                        name="twitter_url"
                                    />

                                    <StyledLabel htmlFor="twitter_tag">
                                        Twitter Handle
                                    </StyledLabel>
                                    <StyledField
                                        id="twitter_tag"
                                        name="twitter_tag"
                                    />

                                    <StyledLabel htmlFor="youtube_url">
                                        Youtube Link
                                    </StyledLabel>
                                    <StyledField
                                        id="youtube_url"
                                        name="youtube_url"
                                    />

                                    <StyledLabel htmlFor="youtube_tag">
                                        Youtube Handle
                                    </StyledLabel>
                                    <StyledField
                                        id="youtube_tag"
                                        name="youtube_tag"
                                    />

                                    <StyledLabel htmlFor="instagram_url">
                                        Instagram Link
                                    </StyledLabel>
                                    <StyledField
                                        id="instagram_url"
                                        name="instagram_url"
                                    />

                                    <StyledLabel htmlFor="instagram_tag">
                                        Instagram Handle
                                    </StyledLabel>
                                    <StyledField
                                        id="instagram_tag"
                                        name="instagram_tag"
                                    />

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    {submitSuccessful && (
                                        <div
                                            style={{
                                                padding: "3rem 0 0",
                                            }}
                                        >
                                            <h4>Centre updated successfully</h4>
                                        </div>
                                    )}
                                    <SubmitSection>
                                        {" "}
                                        <SubmitButton
                                            hasArrow={true}
                                            type="submit"
                                        >
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            </Container>
        </>
    );
};

export default CentreForm;
