import React, { useState } from "react";
import {
    FormikInput,
    Error,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

import { SubmitButton } from "./formComponents";
import { resetPassword } from "./authActions";

import Success from "./Success";

const ResetSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
        .min(2, "Too short")
        .max(70, "Too long")
        .required("Required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
});

const ResetForm = (props) => {
    const { token } = queryString.parse(useLocation().search);

    if (token === undefined || !token) {
        return (window.location.href = "/auth/forgotten");
    }

    const [state, setState] = useState({
        email: props.email ?? "",
        token: token,
        tokenError: false,
        success: false,
        successMessage: "",
        submitting: false,
    });

    const submitForm = (params, { setErrors }) => {
        setState({
            ...state,
            submitting: true,
        });

        axios
            .post(`/api/v1/password/reset`, params)
            .then(({ data }) => {
                const { response_trans } = data.data;

                setState({
                    ...state,
                    success: true,
                    successMessage: response_trans,
                });
            })
            .catch((e) => {
                parseValidationErrors(e)
                    .then(({ errors }) => {
                        setErrors(errors);
                    })
                    .catch((e) => {
                        if (
                            e.response &&
                            e.response.status &&
                            e.response.status === 403
                        ) {
                            const { response_trans } = e.response.data.data;

                            setState({
                                ...state,
                                tokenError: response_trans,
                            });
                        }
                    });
            })
            .finally(() => {
                setState({
                    ...state,
                    submitting: false,
                });
            });
    };

    const render = () => {
        const { token, tokenError, success, successMessage, email } = state;

        if (success === true) {
            return (
                <>
                    <Success>{successMessage}</Success>
                    <p>
                        <Link to="/auth/login" className="button">
                            Go to Login
                        </Link>
                    </p>
                </>
            );
        }

        return (
            <>
                <p>Passwords must be a minimum of 8 characters.</p>
                <p>
                    Using a mixture of special characters, letters and numbers
                    will make your password more difficult to guess.
                </p>

                {tokenError && <Error>{tokenError}</Error>}

                <Formik
                    initialValues={{
                        token: token,
                        email: email,
                        password: "",
                        password_confirmation: "",
                    }}
                    onSubmit={submitForm}
                    validationSchema={ResetSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <FormikInput
                                type="hidden"
                                name="token"
                                placeholder=""
                                value={token}
                                readOnly={true}
                            />

                            <FormikInput
                                type={email === "" ? "text" : "hidden"}
                                name="email"
                                label={email === "" ? "Email" : ""}
                                placeholder="user@domain.com"
                            />
                            <FormikInput
                                type="password"
                                name="password"
                                label="New Password"
                                placeholder=""
                            />
                            <FormikInput
                                type="password"
                                name="password_confirmation"
                                label="Confirm Password"
                                placeholder=""
                            />
                            <SubmitButton
                                expanded
                                label="Reset Password"
                                submitting={state.submitting}
                                disabled={state.submitting}
                            >
                                Reset Password
                            </SubmitButton>

                            {state.submitting && (
                                <LoadingIconSmall
                                    style={{
                                        display: "inline-block",
                                        position: "relative",
                                        top: "10px",
                                        left: "10px",
                                    }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <div>
            <h1>Reset Password</h1>
            {render()}
        </div>
    );
};

export default ResetForm;
