import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Loading, Pagination, Button } from "@cortexglobal/rla-components";
import { Table, TableHeader, TableRow } from "../../components/TableComponents";
import ContactRow from "./ContactRow";
import {
    LimitedWidthRow,
    FlexRow,
    LittleLoader,
} from "../../components/LayoutComponents";
import { StyledInput } from "../../components/FormComponents";
import moment from "moment";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LoadingPage from "../../components/LoadingPage";
import { AuthContext } from "@cortexglobal/lens-auth";

export const loadContact = (contactUuid) => {
    return axios.get(`api/v1/centre/contacts/${contactUuid}`);
};
const Contacts = (props) => {
    const [contacts, setContacts] = useState({
        loaded: false,
        loading: false,
        data: [],
        pagination: {},
    });
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");

    const { user } = useContext(AuthContext);
    const { centre } = user;

    useEffect(() => {
        loadContacts();
    }, []);

    const loadContacts = (page = 1, status = "", search = "") => {
        setContacts({ ...contacts, loading: true, loaded: false });

        return axios
            .get(`api/v1/centre/contacts?page=${page}&search=${search}`)
            .then(({ data }) => {
                setContacts({
                    loaded: true,
                    loading: false,
                    data: data.data,
                    pagination: data.meta,
                });
            });
    };

    const refreshContacts = () => {
        return loadContacts(page, status, search);
    };

    return (
        <>
            <LimitedWidthRow>
                <FlexRow style={{ marginBottom: "2rem" }}>
                    <h3 style={{ flexGrow: "1" }}>Contacts</h3>
                    {contacts.loading && (
                        <LittleLoader icon="circle-notch" spin />
                    )}

                    <FlexRow>
                        <StyledInput
                            style={{
                                marginBottom: "0",
                            }}
                            padding="0 1rem"
                            value={search}
                            onChange={(input) => setSearch(input.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    loadContacts(page, status, search);
                                }
                            }}
                        />
                        <Button
                            onClick={() => loadContacts(page, status, search)}
                        >
                            {" "}
                            <FontAwesomeIcon icon="search" />
                        </Button>
                    </FlexRow>
                </FlexRow>
                {contacts.loaded ? (
                    contacts.data.length > 0 ? (
                        <>
                            <Table>
                                <TableRow>
                                    <TableHeader grow={1}>Name</TableHeader>

                                    <TableHeader>Email</TableHeader>
                                    <TableHeader>Reference</TableHeader>
                                    {!centre && (
                                        <TableHeader>Centre</TableHeader>
                                    )}
                                    <TableHeader></TableHeader>
                                </TableRow>
                                {contacts.data.map((contact) => {
                                    return (
                                        <ContactRow
                                            contact={contact}
                                            centre={centre}
                                        />
                                    );
                                })}
                            </Table>
                            <Pagination
                                currentPage={page - 1}
                                total={contacts.pagination.total}
                                pageCount={contacts.pagination.last_page}
                                onPageChange={({ selected }) => {
                                    setPage(selected + 1);
                                    loadContacts(selected + 1);
                                }}
                                previousLabel="&laquo;"
                                nextLabel="&raquo;"
                            />{" "}
                        </>
                    ) : (
                        <div>No Contacts found</div>
                    )
                ) : (
                    <LoadingPage title="Loading Contact data" />
                )}
            </LimitedWidthRow>
        </>
    );
};

export default Contacts;
