import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Loading, Pagination, Button } from "@cortexglobal/rla-components";
import { Table, TableHeader, TableRow } from "../../components/TableComponents";
import HostedEventRow from "./HostedEventRow";
import {
    LimitedWidthRow,
    FlexRow,
    LittleLoader,
} from "../../components/LayoutComponents";
import { StyledInput } from "../../components/FormComponents";
import moment from "moment";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LoadingPage from "../../components/LoadingPage";
import { AuthContext } from "@cortexglobal/lens-auth";

const DatePickerWrapper = styled.div`
    margin-right: 1rem;

    .react-datepicker__input-container input {
        padding: 0.8em;
        max-width: 100px;
        box-sizing: border-box;
    }

    .react-datepicker-wrapper {
        margin-bottom: 0;
    }
`;

const Dashboard = (props) => {
    const [hostedEvents, setHostedEvents] = useState({
        loaded: false,
        loading: false,
        data: [],
        pagination: {},
    });
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    // const [fromDate, setFromDate] = useState();
    // const [toDate, setToDate] = useState();

    const { user } = useContext(AuthContext);
    const { centre } = user;

    useEffect(() => {
        loadHostedEvents();
    }, []);

    const loadHostedEvents = (page = 1, status = "", search = "") => {
        setHostedEvents({ ...hostedEvents, loading: true, loaded: false });

        // const fromDateVar = new Date(fromDate);
        // const formattedFromDate = `${fromDateVar.getDay()}-${fromDateVar.getMonth()}-${fromDateVar.getFullYear()}`;

        // const toDateVar = new Date(fromDate);
        // const formattedToDate = `${toDateVar.getDay()}-${toDateVar.getMonth()}-${toDateVar.getFullYear()}`;

        // let qsFrom = buildDateQry(fromDate, "from");
        // let qsTo = buildDateQry(toDate, "to");

        return axios
            .get(
                `api/v1/centre/events?page=${page}&search=${search}&status=${status}`
            )
            .then(({ data }) => {
                setHostedEvents({
                    loaded: true,
                    loading: false,
                    data: data.data,
                    pagination: data.meta,
                });
            });
    };

    const buildDateQry = (dte, key) => {
        if (dte) {
            return `&${key}=${moment(dte).format("DD-MM-YYYY")}`;
        }
        return "";
    };

    const refreshHostedEvents = () => {
        return loadHostedEvents(page, status, search);
    };

    return (
        <>
            <LimitedWidthRow>
                <FlexRow style={{ marginBottom: "2rem" }}>
                    <h3 style={{ flexGrow: "1" }}>Events</h3>
                    {hostedEvents.loading && (
                        <LittleLoader icon="circle-notch" spin />
                    )}
                    <FlexRow>
                        <StyledInput
                            style={{
                                marginBottom: "0",
                            }}
                            padding="0 1rem"
                            value={search}
                            onChange={(input) => setSearch(input.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    loadHostedEvents(page, status, search);
                                }
                            }}
                        />
                        <Button
                            onClick={() =>
                                loadHostedEvents(page, status, search)
                            }
                        >
                            {" "}
                            <FontAwesomeIcon icon="search" />
                        </Button>
                    </FlexRow>
                </FlexRow>
                {hostedEvents.loaded ? (
                    hostedEvents.data.length > 0 ? (
                        <>
                            <Table>
                                <TableRow>
                                    <TableHeader grow={1}>
                                        Series/Event
                                    </TableHeader>

                                    <TableHeader></TableHeader>
                                </TableRow>
                                {hostedEvents.data.map((hostedEvent) => {
                                    return (
                                        <HostedEventRow
                                            hostedEvent={hostedEvent}
                                            centre={centre}
                                        />
                                    );
                                })}
                            </Table>
                            <Pagination
                                currentPage={page - 1}
                                total={hostedEvents.pagination.total}
                                pageCount={hostedEvents.pagination.last_page}
                                onPageChange={({ selected }) => {
                                    setPage(selected + 1);
                                    loadHostedEvents(selected + 1);
                                }}
                                previousLabel="&laquo;"
                                nextLabel="&raquo;"
                            />{" "}
                        </>
                    ) : (
                        <div>No Events found</div>
                    )
                ) : (
                    <LoadingPage title="Loading Events data" />
                )}
            </LimitedWidthRow>
        </>
    );
};

export default Dashboard;
