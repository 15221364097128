import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
} from "../../components/FormComponents";

const Schema = Yup.object().shape({
    party_size: Yup.number().required(),
    status: Yup.mixed().oneOf(["booked", "cancelled"]).required(),
});

export const EditBooking = ({
    booking,
    event,
    stopEditingBooking,
    loadEvent,
}) => {
    const handleOnSubmit = (values) => {
        // setSubmitting(true);
        axios
            .put(`/api/v1/booking/${booking.uuid}`, {
                ...values,
            })
            .then(({ data }) => {
                loadEvent(event.uuid).then(() => stopEditingBooking());
                // setSuccessful(true);
                // setSubmitting(false);
            })
            .catch((e) => {
                // setSubmitError(true);
                // setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={{
                party_size: booking.party_size ? booking.party_size : 1,
                status: booking.status ? booking.status : "booked",
            }}
            validationSchema={Schema}
            onSubmit={handleOnSubmit}
        >
            {({ errors, touched, setFieldValue, values }) => {
                return (
                    <Form>
                        <StyledLabel htmlFor="party_size">
                            Edit the number in the party ({event.min_party_size}
                            -{event.max_party_size})<Required />
                        </StyledLabel>
                        <StyledField
                            id="party_size"
                            name="party_size"
                            type="number"
                            min={event.min_party_size}
                            max={event.max_party_size}
                        />
                        {errors.party_size && touched.party_size ? (
                            <Error>The number in the party is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="status">
                            Status <Required />
                        </StyledLabel>
                        <StyledField
                            as="select"
                            name="status"
                            value={values.status}
                            onChange={(value) =>
                                setFieldValue("status", value.target.value)
                            }
                        >
                            <option value="booked">Booked</option>
                            <option value="cancelled">Cancelled</option>
                        </StyledField>
                        {errors.status && touched.status ? (
                            <Error>Status is required</Error>
                        ) : null}

                        <SubmitSection>
                            <SubmitButton
                                style={{
                                    marginRight: "1rem",
                                }}
                                onClick={stopEditingBooking}
                                type="button"
                            >
                                Cancel
                            </SubmitButton>

                            <div
                                style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton type="submit">
                                    Update Booking
                                </SubmitButton>
                            </div>
                        </SubmitSection>
                        {/* <pre>{JSON.stringify(booking, null, 2)}</pre> */}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditBooking;
