import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

class HeadContent extends Component {
    render() {
        return (
            <Helmet>
                <title>Porsche Events</title>
                <meta name="description" content="Porsche Events" />
            </Helmet>
        );
    }
}

export default HeadContent;
