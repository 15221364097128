import React, { useState } from "react";
import { InputField, Error } from "@cortexglobal/rla-components";
import { get } from "lodash";
import { login } from "./authActions";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { SubmitButton } from "./formComponents";

const LoginForm = (props) => {
    const [loadingState, setLoadingState] = useState("loaded");
    const [params, setParams] = useState({});
    const [error, setError] = useState(false);

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    const from = get(params, "location.state.from.pathname", "/");
    const redirect = get(props, "redirect", from);

    const onSubmit = () => {
        setLoadingState("loading");

        login(params, redirect).then((result) => {
            if (result === false) {
                setError(true);
                setLoadingState("loaded");
            }
        });
    };

    return (
        <>
            <h1>Login</h1>
            {error && <Error>Login details not recognised.</Error>}
            <InputField
                name="username"
                label="Email Address"
                onChange={(e) => onChange(e.name, e.value)}
                error={error}
                disabled={loadingState === "loading"}
            />
            <InputField
                type="password"
                name="password"
                label="Password"
                onChange={(e) => onChange(e.name, e.value)}
                error={error}
                disabled={loadingState === "loading"}
            />
            <SubmitButton
                label="Submit"
                expanded
                onClick={() => onSubmit()}
                submitting={loadingState === "loading"}
            >
                Submit
            </SubmitButton>
            <div style={{ marginTop: '1rem' }}>
            {props.forgotten && (
                <Link to="/auth/forgotten">Forgotten Password</Link>
            )}
            </div>
        </>
    );
};

export default LoginForm;
