import React from "react";
import { Route, Switch } from "react-router-dom";

import PublicHeader from "./PublicHeader";
import RegisterForm from "../../modules/registration/RegisterForm";
import PreferencesApp from "../../modules/preferences/PreferencesApp";
import PageNotFound from "../PageNotFound";

const PublicApp = () => {
    return (
        <>
            <PublicHeader />
            <Switch>
                <Route
                    path="/nova"
                    render={() =>
                        (window.location.href =
                            process.env.REACT_APP_BASE_URL + "/nova")
                    }
                />
                <Route path="/preferences" component={PreferencesApp} />
                <Route path="/:event/:code" component={RegisterForm} />
                <Route path="/:event" component={RegisterForm} />

                <Route path="/" component={PageNotFound} />
            </Switch>
        </>
    );
};

export default PublicApp;
