function globalCss(theme) {
    return `
        //Fonts
         ${false && theme.fonts}

        html, body, #root{
            height: 100%;
        }

        body {
            font-size: ${theme.body.fontSize}px;
            background: ${theme.body.background};
            color: ${theme.body.color};
            font-family: ${theme.body.fontFamily};
            margin: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
	        -moz-osx-font-smoothing: grayscale;
        }
        *{
            -webkit-font-smoothing: inherit;
            box-sizing: inherit;
        }
        h1, h2, h3, h4, h5, h6{
            margin: 0 0 ${theme.typography.margin}rem 0;
        }
        p{
            margin: 0 0 ${theme.typography.margin}rem;
        }
        a{
            color: ${theme.anchor.color};
            font-weight: 500;
        }
        fieldset{
            margin: 0;
            padding: 0;
            border: none;
        }
        
        .checklist ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        .checklist ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }

        .ReactCollapse--collapse {
            transition: height 500ms;
        }

        table.vertical-table{
            width: 100%;
            border-spacing: 0;
            margin-bottom: ${theme.spacing.margin}em;
            th{
                text-transform: uppercase;
                color: ${theme.colors.gray200};
            }
            th, td{
                padding: 10px;
                text-align: left;
                vertical-align: top;
            }
            tr:not(:last-child){
                th, td{
            
                    border-bottom: 1px solid ${theme.colors.secondary};
                }
            }
        }

        // React grid style
        .react-grid-item.react-grid-placeholder{
            background: #000000;
            filter: blur(5px);
        }

        // Progress Bar style
        #nprogress{
            .bar{
                background: ${theme.colors.primary};
            }
            .peg{
                box-shadow: 0 0 10px ${theme.colors.primary}, 0 0 5px ${
        theme.colors.primary
    };
            }
            .spinner-icon{
                border-top-color: ${theme.colors.primary};
                border-left-color: ${theme.colors.primary};
            }
        }

        // Input range style
        .input-range__slider{
            background: ${theme.colors.primary};
            border: 1px solid ${theme.colors.primary};
        }

        .input-range__track--active{
            background: ${theme.colors.primary};
        }

        // Custom select style
        .Select-control{
            background: ${theme.select.background} !important;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            border: none;
        }
        .Select-arrow{
            border-top-color: ${theme.select.color};
           
        }
        .Select-arrow-zone:hover > .Select-arrow{
            border-top-color: ${theme.select.color} !important;
            opacity: 0.5;
        }
        .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, 
        .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label{
            color: ${theme.select.color};
        }
        .Select-menu-outer, .Select-option{
            background: ${theme.select.background};
            color: ${theme.select.color};
            border-color: ${theme.select.hover};
        }
        .Select-option.is-selected{
            background: ${theme.select.highlighted}
        }
        .Select-option:hover,
        .Select-option.is-focused{
            background: ${theme.select.hover};
            color: ${theme.select.color};
        }
        .Select-input input{
            color: ${theme.select.color};
        }

        // Injectable Tooltip style
        .injectableTooltip__body{
            background: ${theme.colors.black};
            color: ${theme.colors.white};
        }
        .injectableTooltip__arrow:after{
            background: ${theme.colors.black};
        }

        .pageTitle div{
            padding: 0px,
        }

        .pageTitle h1{
            font-size: 12pt,
        }
    `;
}

export default globalCss;
