import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    -webkit-transform: rotateX(-20deg) rotateY(20deg);
  }

  to {
    -webkit-transform: rotateX(-20deg) rotateY(740deg);
  }
`;

const Cube = styled.div`
    -webkit-transform-style: preserve-3d;
    -webkit-animation: ${spin} 3s infinite ease-out;
    position: relative;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    top: 50%;
    left: 50%;
    margin-left: -${({ size }) => `${size / 2}px`};
    margin-top: -${({ size }) => `${size / 2}px`};
    
    & div {
        width: ${({ size }) => `${size}px`};
        height: ${({ size }) => `${size}px`};
        line-height: ${({ size }) => `${size}px`};
        text-align: center;
        box-shadow: inset 0px 0px 0px 1px rgba(10, 0, 0, 0.8);
        background: rgba(57, 105, 160, 0.6);
        display: block;
        position: absolute;
    }
    
    & div.top {
        -webkit-transform: rotateX(90deg); 
        margin-top: -${({ size }) => `${size / 2}px`};
    }
    
    & div.right {
        -webkit-transform: rotateY(90deg); 
        margin-left: ${({ size }) => `${size / 2}px`};
    }
    
    & div.bottom {
        -webkit-transform: rotateX(-90deg); 
        margin-top: ${({ size }) => `${size / 2}px`};
    }
    
    & div.left {
        -webkit-transform: rotateY(-90deg); 
        margin-left: -${({ size }) => `${size / 2}px`};
    }
    
    & div.front {
        -webkit-transform: translateZ(${({ size }) => `${size / 2}px`})
    }
    
    & div.back {
        -webkit-transform: translateZ(-${({ size }) => `${size / 2}px`}) rotateX(180deg);
    }
`;

const CubeContainer = styled.div`
    display: block;
    position: relative;
    margin: ${({ margin }) => margin};
`;

const CubeLoader = ({ size = 70, margin = '7em' }) => {
    return (
        <CubeContainer
            margin={margin}
        >
            <Cube
                size={size}
            >
                <div className="top" />
                <div className="right" />
                <div className="bottom" />
                <div className="left" />
                <div className="front" />
                <div className="back" />
            </Cube>
        </CubeContainer>
    )
}

export default CubeLoader;