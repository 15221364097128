import React from "react";
import { Loading, Row, Column } from "@cortexglobal/rla-components";

const LoadingPage = props => {
    return (
        <div>
            <Row>
                <Column>
                    <h1>{props.title || "Loading..."}</h1>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Loading />
                </Column>
            </Row>
        </div>
    );
}

export default LoadingPage;