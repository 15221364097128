import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { format } from "date-fns";
import { useSpring, animated } from "react-spring";

import {
    Row,
    Column,
    useAddToast,
    Button,
    Modal,
} from "@cortexglobal/rla-components";
import LoadingPage from "../../components/LoadingPage";
import {
    Table,
    TableHeader,
    TableRow,
    TableData,
} from "../../components/TableComponents";
import { EditBooking } from "./EditBooking";

const HeadingRow = styled.div`
    padding: 0 1.2rem;
    display: flex;
    h4 {
        flex-grow: 1;
    }
`;

const Wrapper = styled.div`
    position: relative;
    overflow-x: hidden;
`;

const Section = styled(animated.div)`
    position: relative;
`;
export const Sessions = ({ event, loadEvent }) => {
    const addToast = useAddToast();
    const [selectedSession, setSelectedSession] = useState(null);
    const [editBooking, setEditBooking] = useState(null);
    const bookingProps = useSpring({
        // opacity: selectedSession !== null ? 1 : 0,
        transform:
            selectedSession === null
                ? "translate3d( -1400px,0, 0)"
                : "translate3d(0, 0, 0)",
    });
    const listProps = useSpring({
        // opacity: selectedSession !== null ? 0 : 1,
        transform:
            selectedSession === null
                ? "translate3d(0, 0, 0)"
                : "translate3d( 1400px, 0, 0)",
        left: selectedSession === null ? 0 : 1400,
    });
    const bookings = selectedSession
        ? event.bookings.filter((booking) => {
              return booking.session_block_uuid === selectedSession.uuid;
          })
        : [];

    const resendBooking = (booking) => {
        axios
            .post(`/api/v1/booking/${booking.uuid}/emails/resend-confirmation`)
            .then(() => {
                addToast({
                    type: "success",
                    content: "Successfully resent the confirmation",
                    showFor: 5000,
                });
            })
            .catch(() => {
                addToast({
                    type: "alert",
                    content: "Error resending the confirmation",
                    showFor: 5000,
                });
            });
    };

    const stopEditingBooking = () => setEditBooking(null);

    const renderSection = () => {
        if (selectedSession !== null) {
            return (
                <Section style={{ ...bookingProps }}>
                    <HeadingRow>
                        <h4>Bookings for the {selectedSession.name} session</h4>
                        <Button
                            onClick={() => {
                                setSelectedSession(null);
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ marginRight: "1rem" }}
                                icon="chevron-left"
                            />{" "}
                            Back to All Sessions
                        </Button>
                    </HeadingRow>
                    {bookings.length === 0 ? (
                        <Row>
                            <Column>
                                No bookings available for this session
                            </Column>
                        </Row>
                    ) : (
                        <Row>
                            <Table>
                                <thead>
                                    <tr>
                                        <TableHeader>Name</TableHeader>
                                        <TableHeader>Email</TableHeader>
                                        <TableHeader>Phone</TableHeader>
                                        <TableHeader>Party Size</TableHeader>
                                        <TableHeader>Reference</TableHeader>
                                        {/* <TableHeader>
                                                Requirements
                                            </TableHeader> */}
                                        {/* <TableHeader>Status</TableHeader> */}
                                        <TableHeader></TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookings.map((booking) => {
                                        return (
                                            <TableRow key={booking.uuid}>
                                                <TableData>
                                                    {booking.contact.first_name}{" "}
                                                    {booking.contact.last_name}
                                                </TableData>
                                                <TableData>
                                                    {booking.contact.email}
                                                </TableData>
                                                <TableData>
                                                    {booking.contact.phone}
                                                </TableData>
                                                <TableData>
                                                    {booking.party_size}
                                                </TableData>
                                                <TableData>
                                                    {booking.contact.reference}
                                                </TableData>
                                                {/* <TableData>
                                                        {typeof booking.contact
                                                            .requirement_notes !==
                                                            "undefined" &&
                                                        booking.contact
                                                            .requirement_notes
                                                            ? booking.contact
                                                                  .requirement_notes
                                                            : "-"}
                                                    </TableData> */}
                                                {/* <TableData>
                                                        {
                                                            booking.status
                                                        }
                                                    </TableData> */}
                                                <TableData
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            resendBooking(
                                                                booking
                                                            );
                                                        }}
                                                    >
                                                        Resend Confirmation
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setEditBooking(
                                                                booking
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableData>
                                            </TableRow>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Row>
                    )}
                    <HeadingRow style={{ paddingTop: "2rem" }}>
                        <h4> </h4>
                        <Button
                            onClick={() => {
                                setSelectedSession(null);
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ marginRight: "1rem" }}
                                icon="chevron-left"
                            />{" "}
                            Back to All Sessions
                        </Button>
                    </HeadingRow>

                    <Modal
                        visible={editBooking !== null}
                        onClose={stopEditingBooking}
                        maxWidth="90%"
                    >
                        <EditBooking
                            booking={editBooking}
                            event={event}
                            stopEditingBooking={stopEditingBooking}
                            loadEvent={loadEvent}
                        />
                    </Modal>
                </Section>
            );
        }

        return (
            <Section style={{ ...listProps }}>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Start</TableHeader>
                            <TableHeader>End</TableHeader>
                            <TableHeader>Places</TableHeader>
                            <TableHeader>Your Bookings</TableHeader>
                            <TableHeader>Availability</TableHeader>
                            <TableHeader></TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {event.sessions && event.sessions.length > 0 ? (
                            event.sessions.map((session) => {
                                return (
                                    <TableRow key={session.uuid}>
                                        <TableData>{session.name}</TableData>
                                        <TableData>
                                            {format(
                                                new Date(session.start_date),
                                                "dd/MM/yyyy"
                                            )}{" "}
                                            {session.start_time}
                                        </TableData>
                                        <TableData>
                                            {format(
                                                new Date(session.end_date),
                                                "dd/MM/yyyy"
                                            )}{" "}
                                            {session.end_time}
                                        </TableData>
                                        <TableData>
                                            {session.total_places}
                                        </TableData>
                                        <TableData>
                                            {
                                                event.bookings.filter(
                                                    (booking) => {
                                                        return (
                                                            booking.session_block_uuid ===
                                                            session.uuid
                                                        );
                                                    }
                                                ).length
                                            }
                                        </TableData>
                                        <TableData>
                                            {session.availability.label}
                                        </TableData>
                                        <TableData
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    setSelectedSession(session)
                                                }
                                            >
                                                View Bookings
                                            </Button>
                                        </TableData>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colspan="100%"
                                    style={{
                                        textAlign: "center",
                                        padding: "2rem",
                                    }}
                                >
                                    <h3>No Sessions found</h3>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Section>
        );
    };

    return <Wrapper>{renderSection()}</Wrapper>;
};

export default Sessions;
