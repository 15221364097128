import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import styled from "styled-components";

import LoginForm from "./LoginForm";
import ForgottenForm from "./ForgottenForm";
import ResetForm from "./ResetForm";

const AuthWrapper = styled.div`
    align-items: center;
    justify-content: center;

    height: 100%;
    display: flex;
    flex-direction: column;
`;

const AuthContainer = styled.div`
    padding: 3em;
    border-radius: 4px;
    width: 50%;
    max-width: 500px;
    flex-grow: 1;
`;

const AuthApp = (props) => {
    return (
        <AuthWrapper>
            <AuthContainer>
                <Switch>
                    <Route exact path="/auth/login">
                        <LoginForm
                            login={true}
                            forgotten={props.forgotten}
                            redirect={props.redirect || "/"}
                        />
                    </Route>

                    <Route exact path="/auth/forgotten">
                        <ForgottenForm />
                    </Route>

                    <Route exact path="/auth/reset">
                        <ResetForm />
                    </Route>
                </Switch>
            </AuthContainer>
        </AuthWrapper>
    );
};

export default withRouter(AuthApp);
