import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import {
    Button,
    Row,
    Column,
    FormikInput,
} from "@cortexglobal/rla-components";
import { AuthContext } from "@cortexglobal/lens-auth";
import LoadingPage from "../../components/LoadingPage";
import { humanizeSnakeCase } from "../../helpers";
import {
    Table,
    TableHeader,
    TableData,
    TableRow,
} from "../../components/TableComponents";
import { loadContact } from "./Contacts";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
    SubmitButton,
    Loader,
    Required,
    StyledField,
} from "../../components/FormComponents";
import axios from "axios";

const SpacedRow = styled(Row)`
    margin-top: 2.5rem;
`;

const Schema = Yup.object().shape({
    email: Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
});

export const StyledLabel = styled.label`
    margin: 1em 0 0.5em;
    display: block;
`;

const ContactEdit = ({ match }) => {
    const history = useHistory();
    const [contactDetails, setContactDetails] = useState({
        loaded: false,
        data: {},
    });

    const { user } = useContext(AuthContext);
    const { centre } = user;

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        loadContact(match.params.uuid).then(({ data }) => {
            setContactDetails({ loaded: true, data: data.data });
        });
    }, []);

    if (!contactDetails.loaded) {
        return <LoadingPage title="Loading contact details" />;
    }

    const contact = contactDetails.data;

    const handleSubmit = (values) => {
        setSubmitting(true);

        axios.put(`api/v1/centre/contacts/${contact.uuid}`, values)
            .then(() => {
                setSubmitting(false);
                history.push(`/centre-area/contacts/${contact.uuid}/view`);
            })
            .then(e => {
                console.log(e);
            });
    };

    return (
        <>
            <Row>
                <Column>
                    <h2>
                        Edit contact details
                    </h2>
                </Column>
            </Row>
            <Row>
                <Column xlarge={6} large={8} medium={12}>
                    <Formik
                        initialValues={{
                            email: contact.email ? contact.email : "",
                            first_name: contact.first_name ? contact.first_name : "",
                            last_name: contact.last_name ? contact.last_name : "",
                            phone: contact.phone ? contact.phone : "",
                            title: contact.title ? contact.title : "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Schema}
                    >
                        {({
                              isSubmitting,
                              values,
                              setFieldValue,
                              handleBlur,
                              errors
                          }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="email">
                                        Email
                                    </StyledLabel>
                                    <StyledField
                                        value={values.email}
                                        width="100%"
                                        labelWidth={100}
                                        name="email"
                                    />

                                    <StyledLabel htmlFor="title">
                                        Title
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="title"
                                        value={values.title}
                                        onChange={(value) =>
                                            setFieldValue("title", value.target.value)
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Dr">Dr</option>
                                    </StyledField>

                                    <StyledLabel htmlFor="first_name">
                                        First name
                                    </StyledLabel>
                                    <StyledField
                                        value={values.first_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="first_name"
                                    />

                                    <StyledLabel htmlFor="last_name">
                                        Last name
                                    </StyledLabel>
                                    <StyledField
                                        value={values.last_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="last_name"
                                    />

                                    <StyledLabel htmlFor="phone">
                                        Phone
                                    </StyledLabel>
                                    <StyledField
                                        value={values.phone}
                                        width="100%"
                                        labelWidth={100}
                                        name="phone"
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <SubmitButton style={{ marginRight: "1rem" }}>
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Column>

            </Row>
        </>
    );
};

export default ContactEdit;
