import React, { Component } from "react";
import { Row, Column, Footer as RlaFooter } from "@cortexglobal/rla-components";

let d = new Date(),
    n = d.getFullYear(),
    copyrightYear = "2019",
    currentYear = n;

class Footer extends Component {
    render() {
        return (
            <RlaFooter>
                <Row>
                    <Column>
                        <p>
                            &copy; Porsche{" "}
                            {copyrightYear >= currentYear
                                ? currentYear
                                : copyrightYear + " - " + currentYear}
                        </p>
                    </Column>
                </Row>
            </RlaFooter>
        );
    }
}

export default Footer;
