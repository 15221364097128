import React from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";

// routing
import PrivateRoute from "./components/global/PrivateRoute";
import PublicApp from "./components/global/PublicApp";
import PrivateApp from "./components/global/PrivateApp";

import PageNotFound from "./components/PageNotFound";

import Logo from "./components/Logo";

// content
import HeadContent from "./components/global/HeadContent";
import { ToastList } from "@cortexglobal/rla-components";
import { AuthApp } from "@cortexglobal/lens-auth";

//styling
import { createGlobalStyle } from "styled-components";
import Theme from "./config/theme";
import globalCss from "./config/globalCss";

const GlobalStyle = createGlobalStyle`${globalCss(Theme)}`;

const LogoSection = styled.div`
    border-bottom: 1px solid #d6d6d6;
    text-align: center;
    margin-bottom: 2rem;
`;

const StyledLogo = styled(Logo)`
    position: relative;
    top: 1.5rem;
    background: white;
    border: 1rem solid white;
    box-sizing: border-box;
    max-width: 135px;
`;

const App = () => {
    return (
        <div style={{ height: "100%" }}>
            <GlobalStyle />
            <HeadContent />

            <Switch>
                <Route path="/auth">
                    <LogoSection>
                        <StyledLogo />
                    </LogoSection>
                    <AuthApp
                        login={true}
                        forgotten={true}
                        redirect="/centre-area"
                    />
                </Route>
                <PrivateRoute path="/centre-area" component={PrivateApp} />
                <Route path="/" component={PublicApp} />
                <Route path="*">
                    <PageNotFound redirect="/" />
                </Route>
            </Switch>
            <ToastList />
        </div>
    );
};

export default App;
