import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "./RegisterForm";

const Hero = styled.div`
    height: 50vh;
    background: url(${({ background }) => background}) no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    color: white;
`;

const Title = styled.h1`
    margin-top: 0;
    padding-top: 1rem;
`;

const HeroSection = ({ eventDetails }) => {
    return (
        <Hero background={eventDetails.heroImage}>
            <Container
                style={{
                    height: "30%",
                    width: "100%",
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%", textAlign: "center" }}>
                    <Title>{eventDetails.title}</Title>
                    <h2>
                        {eventDetails.subheading
                            ? eventDetails.subheading + " "
                            : ""}
                        {eventDetails.tagline}
                    </h2>
                </div>
            </Container>
        </Hero>
    );
};

export default HeroSection;
