import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Button } from "@cortexglobal/rla-components";

import { TableData, TableRow } from "../../components/TableComponents";
const ContactRow = ({ contact, centre }) => {
    return (
        <>
            <TableRow>
                <TableData>
                    {contact.first_name} {contact.last_name}
                </TableData>
                <TableData>{contact.email ? contact.email : "-"}</TableData>
                {!centre && (
                    <TableData>
                        {contact.centre ? contact.centre.name : "-"}
                    </TableData>
                )}
                <TableData>
                    {contact.reference ? contact.reference : "-"}
                </TableData>
                <TableData style={{ textAlign: "right" }}>
                    <Button
                        style={{ lineHeight: "36px" }}
                        as={Link}
                        to={`/centre-area/contacts/${contact.uuid}/view`}
                    >
                        View
                    </Button>
                    <Button
                        style={{ lineHeight: "36px" }}
                        as={Link}
                        to={`/centre-area/contacts/${contact.uuid}/edit`}
                    >
                        Edit
                    </Button>
                </TableData>
            </TableRow>
        </>
    );
};

export default ContactRow;
