import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import UploadForm from "./UploadForm";
import UploadListing from "./UploadListing";

const Uploads = (props) => {
    const [uploads, setUploads] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loadingState, setLoadingState] = useState("loaded");

    const refreshList = () => {
        getList(1);
    };

    const getList = (page) => {
        setLoadingState("loading");

        const params = {
            page: page === undefined ? 1 : page,
        };

        axios
            .get(`/api/v1/uploads`, { params: params })
            .then(({ data }) => {
                setUploads(data.data);
                setPagination(data.meta);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => {
        getList(1);
    }, []);

    return (
        <>
            <PageTitle title="Uploads" expanded />

            <Row collapse expanded>
                <Column xlarge={6}>
                    <UploadForm callback={refreshList} />
                </Column>
            </Row>

            <Row collapse expanded>
                <Column collapse>
                    <Panel style={{ paddingTop: "0" }}>
                        <UploadListing
                            uploads={uploads}
                            pagination={pagination}
                            reload={getList}
                            loadingState={loadingState}
                        />
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default Uploads;
