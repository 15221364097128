import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LimitedWidthRow = styled.div`
    width: calc(86vw);
    max-width: 96rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
`;
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const LittleLoader = styled(FontAwesomeIcon)`
    padding: 0.5rem;
`;
