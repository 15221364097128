import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
} from "../../components/FormComponents";

import LoadingPage from "../../components/LoadingPage";

const DetailSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const PasswordChangeSchema = Yup.object().shape({
    current_password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    confirm_password: Yup.string().required("Required"), //TODO And matches new
});

const ProfileForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitPasswordSuccessful, setSubmitPasswordSuccessful] =
        useState(false);
    const [profile, setProfile] = useState({
        loaded: false,
        error: false,
        data: {},
    });

    useEffect(() => {
        axios
            .get(`/api/v1/profile`)
            .then(({ data }) => {
                setProfile({ loaded: true, error: false, data: data.data });
            })
            .catch((e) => {
                setProfile({ loaded: true, error: true, data: {} });
            });
    }, []);

    const handleDetailsOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .put(`/api/v1/profile`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    const handlePasswordOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .put(`/api/v1/profile/password-update`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitPasswordSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    if (!profile.loaded) {
        return <LoadingPage title="Loading your details" />;
    }

    return (
        <>
            <Container>
                <>
                    <h1 className="title" style={{ marginBottom: "3rem" }}>
                        Your Details
                    </h1>
                    <Formik
                        initialValues={{
                            name: profile.data.name,
                            email: profile.data.email,
                        }}
                        validationSchema={DetailSchema}
                        onSubmit={handleDetailsOnSubmit}
                    >
                        {({ errors, touched }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="name">
                                        Name <Required />
                                    </StyledLabel>
                                    <StyledField id="name" name="name" />
                                    {errors.name && touched.name ? (
                                        <Error>Name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>Email is required</Error>
                                    ) : null}

                                    {/* <StyledLabel htmlFor="password">
                                            Password <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="password"
                                            name="password"
                                        />
                                        {errors.password &&
                                        touched.password ? (
                                            <Error>
                                                Password is required
                                            </Error>
                                        ) : null} */}

                                    {submitSuccessful && (
                                        <>
                                            <div style={{ padding: "3rem 0 " }}>
                                                <h3>
                                                    Profile submitted
                                                    successfully
                                                </h3>
                                            </div>
                                        </>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton
                                            hasArrow={true}
                                            type="submit"
                                        >
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            </Container>
            <Container>
                <>
                    <h1 className="title" style={{ marginBottom: "3rem" }}>
                        Reset Password
                    </h1>
                    <Formik
                        initialValues={{
                            current_password: "",
                            new_password: "",
                            confirm_password: "",
                        }}
                        validationSchema={PasswordChangeSchema}
                        onSubmit={handlePasswordOnSubmit}
                    >
                        {({ errors, touched }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="current_password">
                                        Current Password <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="current_password"
                                        name="current_password"
                                        type="password"
                                    />
                                    {errors.current_password &&
                                    touched.current_password ? (
                                        <Error>
                                            Current Password is required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="new_password">
                                        New Password <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="new_password"
                                        name="new_password"
                                        type="password"
                                    />
                                    {errors.new_password &&
                                    touched.new_password ? (
                                        <Error>New Password is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="confirm_password">
                                        Confirm Password <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="confirm_password"
                                        name="confirm_password"
                                        type="password"
                                    />
                                    {errors.confirm_password &&
                                    touched.confirm_password ? (
                                        <Error>{errors.confirm_password}</Error>
                                    ) : null}

                                    {submitPasswordSuccessful && (
                                        <>
                                            <div style={{ padding: "3rem 0 " }}>
                                                <h3>
                                                    Password updated
                                                    successfully
                                                </h3>
                                            </div>
                                        </>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton
                                            hasArrow={true}
                                            type="submit"
                                        >
                                            Reset Password
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            </Container>
        </>
    );
};

export default ProfileForm;
