import React from "react";
import {
    Required,
    StyledCheckbox,
    StyledField,
    StyledLabel,
} from "../../components/FormComponents";

const LeadQuestion = ({
    name,
    label,
    options,
    required,
    type,
    setFieldValue,
    values,
}) => {
    switch (type) {
        case "checkbox":
            return (
                <>
                    <StyledLabel htmlFor={name}>
                        <StyledCheckbox
                            type="checkbox"
                            id={name}
                            name={name}
                            value={values[name]}
                            onChange={(value) =>
                                setFieldValue(name, value.target.checked)
                            }
                        />
                        {required && <Required />}
                        {label}
                    </StyledLabel>
                </>
            );
        case "radio":
            return (
                <>
                    {Array.isArray(options) &&
                        options.map((option) => {
                            const { text } = option;
                            return (
                                <>
                                    <StyledCheckbox
                                        type="radio"
                                        id={name}
                                        name={name}
                                        value={values[name]}
                                        onChange={(value) =>
                                            setFieldValue(
                                                name,
                                                value.target.checked
                                            )
                                        }
                                    />
                                    {" " + text}
                                </>
                            );
                        })}

                    {required && <Required />}
                    <StyledLabel htmlFor={name}>{label}</StyledLabel>
                </>
            );
        default:
            return (
                <>
                    <StyledLabel htmlFor={name}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: label,
                            }}
                        />{" "}
                        {required && <Required />}
                    </StyledLabel>
                    <StyledField
                        id={name}
                        name={name}
                        value={values[name]}
                        onChange={(value) =>
                            setFieldValue(name, value.target.value)
                        }
                    />
                </>
            );
    }
};

export default LeadQuestion;
