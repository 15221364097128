import React, { useState } from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import axios from "axios";

import Header from "../../components/global/PublicHeader";
import {
    Container,
    Loader,
    LogoContainer,
    SubmitButton,
    SubmitSection,
} from "../../components/FormComponents";
import Logo from "../../components/Logo";
import { ThankyouBanner } from "../../components/registration/registrationComponents";
import { Button, Modal } from "@cortexglobal/rla-components";

const StyledInput = styled.input`
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;
`;

const PreferencesApp = ({ location }) => {
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [unsubscribeModalShowing, setUnsubscribeModalShowing] =
        useState(false);
    const [unsubscribeReason, setUnsubscribeReason] = useState("");

    const handleUnsubscribe = () => {
        setSubmitting(true);
        setError(false);
        const urlSearchParams = new URLSearchParams(location.search);
        const { id } = Object.fromEntries(urlSearchParams.entries());

        axios
            .post(`/api/v1/preferences`, {
                reason: unsubscribeReason,
                id: id,
                subscribe: 0,
            })
            .then((data) => {
                setSubmitting(false);
                setUnsubscribed(true);
                setUnsubscribeModalShowing(false);
            })
            .catch((e) => {
                setSubmitting(false);
                setError(true);
            });
    };

    return (
        <>
            <Header />

            <LogoContainer>
                <Logo />
            </LogoContainer>

            <ThankyouBanner />

            <Container style={{ marginTop: "3rem" }}>
                {unsubscribed ? (
                    <>
                        <h1>Thank you</h1>
                        <p>You have been successfully unsubscribed.</p>
                    </>
                ) : (
                    <>
                        <h1>Preferences</h1>
                        <p style={{ marginBottom: "2rem" }}>
                            If you would like to unsubscribe from email
                            communications from Porsche Events Register, <br />
                            click the button below.
                        </p>

                        <SubmitSection>
                            <SubmitButton
                                onClick={() => setUnsubscribeModalShowing(true)}
                                hasArrow
                            >
                                Click here to unsubscribe
                            </SubmitButton>

                            {submitting && <Loader />}
                        </SubmitSection>
                    </>
                )}
            </Container>

            <Modal>test</Modal>
            {unsubscribeModalShowing && (
                <Modal
                    visible={true}
                    onClose={() => setUnsubscribeModalShowing(false)}
                >
                    <div style={{ padding: "2rem" }}>
                        <h2>You are about to unsubscribe</h2>
                        <p>
                            Please take a moment to tell us why you no longer
                            want to hear from us:
                        </p>

                        <div style={{ margin: "1rem 0" }}>
                            <StyledInput
                                maxLength={2500}
                                name="unsubscribeReason"
                                type="text"
                                placeholder="optional"
                                value={unsubscribeReason}
                                onChange={(value) =>
                                    setUnsubscribeReason(value.target.value)
                                }
                            />
                        </div>
                        <SubmitButton onClick={handleUnsubscribe} hasArrow>
                            Unsubscribe
                        </SubmitButton>
                        {error && (
                            <p
                                style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginTop: "1rem",
                                }}
                            >
                                There was an error unsubscribing
                            </p>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PreferencesApp;
