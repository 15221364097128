import React from "react";

const SomethingWentWrong = (props) => {
    return (
        <div>
            <p>Oops, something went wrong.</p>
        </div>
    );
};

export default SomethingWentWrong;
