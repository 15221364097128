import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortDown,
    faSortUp,
} from "@fortawesome/free-solid-svg-icons";

export const StyledTable = styled.table`
    margin: 1rem 0;
    width: 100%;
    border-spacing: 0;

    th {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        background: #002a5b;
        color: white;
        text-align: left;
        padding: 10px;
        vertical-align: bottom;
    }

    td {
        text-align: left;
        padding: 10px;
    }

    tr {
        &:nth-child(even) {
            background-color: #efefef;
        }

        ${(props) =>
            props.selectable &&
            `
            cursor: pointer;
            &:hover {
                box-shadow: inset 0 0 0px 2px black;
            }
        `}
    }
`;

const StyledTh = styled.th`
    vertical-align: baseline !important;
    transition: color 200ms ease-out, background-color 200ms ease-out;
    color: #ffffff;
    background-color: #002a5b;
    &.sortable {
        cursor: pointer;

        &:hover {
            color: #dddddd;
            background-color: #003a7d;
        }
    }
`;

const TableHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const TableHeaderSearchContainer = styled.div`
    margin-top: 0.5em;
`;

export const StyledTableRow = styled.tr`
    ${(props) =>
        props.selected &&
        `
        background: #c1ddff !important;
    `}
`;

export const StyledTableHeader = ({
    children,
    isSortable = false,
    sortDirection,
    hasSearch = false,
    onSearchChange,
    onSortChange,
    searchValue,
    searchOptions,
    name,
}) => {
    return (
        <StyledTh className={isSortable ? "sortable" : ""}>
            <TableHeaderContainer>
                <TableHeaderTitle
                    onClick={() => {
                        // First click sets asc; second click sets desc; third click restores default
                        if (isSortable) {
                            let newSortDirection =
                                sortDirection === "asc"
                                    ? "desc"
                                    : sortDirection === "desc"
                                    ? null
                                    : "asc";
                            let newName =
                                newSortDirection === null ? null : name;
                            onSortChange(newSortDirection, newName);
                        }
                    }}
                >
                    {children}
                    {isSortable && (
                        <FontAwesomeIcon
                            icon={
                                sortDirection === "asc"
                                    ? faSortUp
                                    : sortDirection === "desc"
                                    ? faSortDown
                                    : faSort
                            }
                            size="lg"
                        />
                    )}
                </TableHeaderTitle>
                {hasSearch && (
                    <TableHeaderSearchContainer>
                        {!searchOptions && (
                            <input
                                name="search"
                                onChange={(value) =>
                                    onSearchChange(value.target.value, name)
                                }
                                value={searchValue}
                            />
                        )}
                        {searchOptions && (
                            <select
                                name="optionSearch"
                                onChange={(value) =>
                                    onSearchChange(value.target.value, name)
                                }
                                value={searchValue}
                            >
                                <option key={0} value="">
                                    Select a value
                                </option>
                                {searchOptions.map(({ label, value }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        )}
                    </TableHeaderSearchContainer>
                )}
            </TableHeaderContainer>
        </StyledTh>
    );
};
