import React from "react";
import styled from "styled-components";

import { Loader } from "../../components/FormComponents";
import { Container } from "./RegisterForm";

const Terms = styled.div`
    background: #191f22;
    color: white;

    a {
        color: white;
    }
`;

const CountdownSection = styled.div`
    position: fixed;
    background: white;
    border-top: 1px solid black;
    padding: 2em;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`;

const TermsHeading = styled.h3`
    margin-bottom: 2em;
`;

const FormFooter = ({
    eventDetails,
    countdownStarted,
    countdown,
    timeSlotSubmitting,
}) => {
    const { title, terms_and_conditions } = eventDetails;

    return (
        <div
            style={
                countdownStarted
                    ? {
                          paddingBottom: "4em",
                      }
                    : {}
            }
        >
            {!!terms_and_conditions && (
                <Terms id="conditions" className="terms">
                    <Container>
                        <TermsHeading>{title}</TermsHeading>
                        <TermsHeading>Terms and Conditions</TermsHeading>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: terms_and_conditions,
                            }}
                        />
                    </Container>
                </Terms>
            )}

            {countdownStarted && (
                <CountdownSection>
                    <span>Your spot has been secured for a limited time.</span>
                    <span
                        style={
                            countdown < 60
                                ? {
                                      display: "flex",
                                      alignItems: "center",
                                      color: "red",
                                      fontWeight: "bold",
                                  }
                                : {}
                        }
                    >
                        {!!countdown && (
                            <span style={{ marginRight: "1rem" }}>
                                {Math.floor(countdown / 60)}:
                                {(
                                    "0" +
                                    (countdown -
                                        Math.floor(countdown / 60) * 60)
                                ).slice(-2)}
                            </span>
                        )}

                        {timeSlotSubmitting && <Loader />}
                    </span>
                </CountdownSection>
            )}
        </div>
    );
};

export default FormFooter;
