import styled, { css } from "styled-components";

const ArrowStylesBottom = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 45%;
    left: 15px;
    transform: translateY(-100%) rotate(55deg);
`;

const ArrowStylesTop = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(100%) rotate(-55deg);
`;

export const SubmitButton = styled.button`
    outline: none;
    padding: 1.2em 2em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    background: white;
    border: 1px solid black;
    border-radius: 0;

    transition: all 0.1s;
    position: relative;

    &:hover:not([disabled]) {
        background: #d5001c;
        border: 1px solid #d5001c;
        color: white;
    }

    ${({ color }) =>
        color === "grey" &&
        `
        background: #3e3e3e;
        border: 1px solid #3e3e3e;
        color: white;
    `}

    ${({ color }) =>
        color === "green" &&
        `
        background: #11a211;
        border: 1px solid #11a211;
        color: white;
    `}
    
    ${({ color }) =>
        color === "red" &&
        `
        background: #d5001c;
        border: 1px solid #d5001c;
        color: white;
    `}
    
    ${({ hasArrow, color }) =>
        hasArrow &&
        `
        padding-left: 2.5rem;
    
        &:before {
            ${ArrowStylesBottom};
            background: ${color ? "white" : "black"};
        }
        
        &:after {
            ${ArrowStylesTop};
            background: ${color ? "white" : "black"};
        }
        
        &:hover:not([disabled]) {
        
            &:before {
                background: white !important;
                ${ArrowStylesBottom};
            }
            
            &:after {
                background: white !important;
                ${ArrowStylesTop};
            }
        }
    `}
`;
