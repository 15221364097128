import React from "react";
import styled from "styled-components";

const Success = styled.p`
    background: #eefdec;
    text-align: center;
    padding: 1em;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.03);
`;

export default Success;
