import React from "react";
import styled from "styled-components";
import { Route, Switch, withRouter } from "react-router-dom";

import { SiteWrapper, MainContentWrapper } from "@cortexglobal/rla-components";

import PrivateHeader from "./PrivateHeader";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import PageNotFound from "../PageNotFound";

import Dashboard from "../../modules/admin/Dashboard";
import EventDetails from "../../modules/admin/EventDetails";
import CentreForm from "../../modules/admin/CentreForm";
import ProfileForm from "../../modules/admin/ProfileForm";
import Contacts from "../../modules/admin/Contacts";
import ContactDetails from "../../modules/admin/ContactDetails";
import ContactEdit from "../../modules/admin/ContactEdit";

const StyledSiteWrapper = styled(SiteWrapper)`
    flex-direction: row;
`;

const SiteMain = styled.div`
    flex-grow: 1;
    flex-direction: column;
    display: flex;
`;

const PrivateApp = (props) => {
    return (
        <StyledSiteWrapper>
            {/*<Sidebar />*/}
            <SiteMain>
                <PrivateHeader />
                <MainContentWrapper style={{ position: "relative" }}>
                    <Switch>
                        <Route
                            exact
                            path="/centre-area"
                            component={Dashboard}
                        />
                        <Route
                            exact
                            path="/centre-area/event/:uuid"
                            component={EventDetails}
                        />

                        <Route
                            path="/centre-area/centre"
                            component={CentreForm}
                        />
                        <Route
                            path="/centre-area/profile"
                            component={ProfileForm}
                        />
                        <Route
                            exact
                            path="/centre-area/contacts"
                            component={Contacts}
                        />
                        <Route
                            path="/centre-area/contacts/:uuid/view"
                            component={ContactDetails}
                        />
                        <Route
                            path="/centre-area/contacts/:uuid/edit"
                            component={ContactEdit}
                        />

                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </MainContentWrapper>
                <Footer />
            </SiteMain>
        </StyledSiteWrapper>
    );
};

export default withRouter(PrivateApp);
