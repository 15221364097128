import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";

const ErrorLogs = (props) => {
    return (
        <>
            <PageTitle title="ErrorLogs">
            </PageTitle>
            <Row>
                <Column>
                    Error logs
                </Column>
            </Row>
        </>
    );
};

export default ErrorLogs;


