import React, { useState } from "react";
import { InputField, Error } from "@cortexglobal/rla-components";
import { get } from "lodash";
import { forgotten } from "./authActions";
import { Link } from "react-router-dom";
import Success from "./Success";
import { SubmitButton } from "./formComponents";
import { LoadingIconSmall } from "@cortexglobal/rla-components";

const ForgottenForm = (props) => {
    const [loadingState, setLoadingState] = useState("loaded");
    const [params, setParams] = useState({});
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState("");

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    const onSubmit = () => {
        setLoadingState("loading");

        forgotten(params).then((result) => {
            const { errors, success } = result;

            setErrors(errors);

            if (success === true) {
                setMessage(
                    "An email has been sent to the address provided containing a password reset link."
                );
            }

            setLoadingState("loaded");
        });
    };

    return (
        <>
            <h1>Forgotten Password</h1>

            {message !== "" ? (
                <Success>{message}</Success>
            ) : (
                <>
                    <InputField
                        name="email"
                        label="Email Address"
                        onChange={(e) => onChange(e.name, e.value)}
                        error={
                            get(errors, "email") ? errors.email[0] : undefined
                        }
                        disabled={loadingState === "loading"}
                    />
                    <SubmitButton
                        expanded
                        onClick={() => onSubmit()}
                        submitting={loadingState === "loading"}
                        disabled={loadingState === "loading"}
                    >
                        Submit
                    </SubmitButton>
                    {loadingState === "loading" && (
                        <LoadingIconSmall
                            style={{
                                display: "inline-block",
                                position: "relative",
                                top: "10px",
                                left: "10px",
                            }}
                        />
                    )}
                </>
            )}

            <div style={{ marginTop: "1rem" }}>
                <Link to="/auth/login">Back to Login</Link>
            </div>
        </>
    );
};

export default ForgottenForm;
