import React from "react";
import styled, { css } from "styled-components";
import { withTheme } from "styled-components";
import { Link } from "react-router-dom";
import { LogoContainer } from "../FormComponents";
import Logo from "../Logo";

const SidebarContainer = styled.div`
    height: 100%;
    width: 200px;
    min-width: 200px;
    position: relative;
    z-index: 1;
    border-right: 1px solid black;
`;

const Title = styled.h1`
    color: black;
    font-weight: bold;
    font-size: 1.3em;
    padding-left: 1em;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const MenuItems = styled.div`
    margin-top: 3em;
`;

const InnerMenu = styled.div`
    position: absolute;
    top: 0;
    left: 100%;
    background: red;
    width: 100%;
    height: 100%;
    display: none;
    cursor: default;
    background: white;
    padding-top: 4em;
`;

const MenuItemStyles = css`
    width: 100%;
    display: inline-block;
    padding: 1em 1em 1em 1.3em;
    color: black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        font-weight: bold;
    }
`;

const MenuItem = styled(Link)`
    ${MenuItemStyles};
`;

const FakeMenuItem = styled.div`
    ${MenuItemStyles};
`;

const MenuItemContainer = styled.div`
    &:hover {
        ${InnerMenu} {
            display: block;
        }
    }
`;

const Sidebar = (theme) => {
    return (
        <SidebarContainer>
            <StyledLink to="/centre-area">
                <LogoContainer>
                    <Logo />
                </LogoContainer>
            </StyledLink>

            <MenuItems>
                <MenuItemContainer>
                    <MenuItem to="/centre-area/uploads">Uploads</MenuItem>
                </MenuItemContainer>

                <MenuItemContainer>
                    <MenuItem to="/centre-area/imports">Imports</MenuItem>
                </MenuItemContainer>
            </MenuItems>
        </SidebarContainer>
    );
};

export default withTheme(Sidebar);
