import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";

const Admin = (props) => {
    return (
        <>
            <p>Admin</p>
        </>
    );
};

export default Admin;

