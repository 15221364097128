import styled from "styled-components";

export const UnstyledButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
`;

export const RadioCheck = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid grey;
    border-radius: ${({ square }) => square ? "0" : "50%"};

    &:after {
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 17px;
        height: 17px;
        border-radius: ${({ square }) => square ? "0" : "50%"};
        background: black;
    }
`;

export const CustomRadio = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + ${RadioCheck} {
        &:after {
            display: block !important;
        }
    }
`;

export const RadioContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 4px;
`;

export const Checkmark = styled.div`
    position: absolute;
    top: 25px;
    left: 85%;

    &:after {
        content: "";
        display: block;
        width: 7px;
        height: 2px;
        transform: rotate(45deg);
        background: #11a211;
    }

    &:before {
        content: "";
        display: block;
        width: 13px;
        height: 2px;
        transform: rotate(-45deg);
        background: #11a211;
        position: relative;
        top: -1px;
        left: 4px;
    }
`;

export const ThankyouBanner = styled.div`
    height: 500px;
    background: url("/porscheclub2.jpg") no-repeat;
    background-size: cover;
    background-position: center;
`;

export const TermsConditions = styled.div`
    padding: 3rem 0;
    background: #222;
    color: white;
`;

export const RadioContainerOuter = styled.div`
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    max-width: 600px;
`;


export const InfoButton = styled.button`
    border: none;
    outline: none;
    border-radius: 50%;
    background: black;
    color: white;
    font-size: 9px;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
    position: relative;
    left: 5px;
    bottom: 2px;
`;

export const PopperElement = styled.div`
    z-index: 2;
`;

export const InfoContainer = styled.div`
    padding: 0.5em 0;
    border-radius: 5px;
    min-width: 100px;
    max-width: 400px;
    background: #3e3e3e;
    color: white;
    padding: 1em;
`;

